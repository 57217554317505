import { FC, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { CalendarIcon, ClockIcon, CrossIcon, SuitCaseIcon } from "../../assets";
import { IconTag, Tag, UserPreview } from "../../components";
import { ICandidate, IEmployer, IVideo } from "../../models";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  item: IVideo;
  user: ICandidate | IEmployer | undefined;
}

const Video: FC<Props> = ({ item, isOpen, setIsOpen, user }): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <CrossIcon
          onClick={() => {
            setIsOpen(false);
          }}
          className={styles.closeBtn}
        />
        <div className={styles.videoWrapper}>
          <video src={item.url} controls className={styles.video} />
        </div>
        <div className={styles.infoWrapper}>
          <UserPreview
            image={user?.image}
            //@ts-ignore
            verified={user?.verificationStatus === "Approved"}
            title={
              user?.firstName
                ? `${user.firstName} ${user.lastName}`
                : //@ts-ignore
                  user.company
            }
          />
          {item.employment && item.workplace && (
            <div className={styles.row}>
              <IconTag icon={<SuitCaseIcon />} title={item.workplace} />
              <IconTag icon={<ClockIcon />} title={item.employment} />
            </div>
          )}
          <div className={styles.tagsContainer}>
            {item.tags.map((item, index) => (
              <Tag key={index} title={item} />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Video;
