import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  ArrowLineIcon,
  DownloadIcon,
  GiftIcon,
  UserPlusIcon,
} from "../../../assets";
import { useNavigate, useParams } from "react-router-dom";
import { Button, RefferalsList, TransactionsList } from "../../../components";
import { PulseLoader } from "react-spinners";
import PlaceholderAvatar from "../../../assets/images/avatar.png";
import { Paid } from "../../../modals";
import { ICandidate } from "../../../models";
import { getCandidateById } from "../../../services/candidate.service";

interface IProps {}

const ReferralProfile: FC<IProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState<"Referrals" | "Transactions">(
    "Referrals"
  );
  const params = useParams();
  const [candidate, setCandidate] = useState<ICandidate | null>(null);
  const [isShowPaidModal, setIsShowPaidModal] = useState(false);

  const onGetCandidate = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getCandidateById(id);
      if (data?.success) {
        setCandidate(data?.candidate);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetCandidate(params?.id as string);
  }, [params?.id]);

  const navigate = useNavigate();

  return (
    <>
      <Paid
        userName={`${candidate?.firstName} ${candidate?.lastName}`}
        candidateId={candidate?.id}
        amount={candidate?.referal?.requested}
        onGetCandidate={onGetCandidate}
        isOpen={isShowPaidModal}
        setIsOpen={setIsShowPaidModal}
      />
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div onClick={() => navigate(-1)} className={styles.backBtn}>
            <ArrowLineIcon />
            <span>Referral</span>
          </div>
        </div>
        <div className={styles.contentContainer}>
          {isLoading ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          ) : (
            <>
              <div className={styles.header}>
                <div className={styles.info}>
                  <img
                    src={candidate?.image || PlaceholderAvatar}
                    alt="avatar"
                    className={styles.avatar}
                  />
                  <div className={styles.nameWrapper}>
                    <p className={styles.name}>
                      {candidate?.firstName} {candidate?.lastName}
                    </p>
                    <p className={styles.location}>{candidate?.location}</p>
                    <p className={styles.location}>{candidate?.email}</p>
                  </div>
                </div>
                <div className={styles.statsContainer}>
                  <div className={styles.statsItemContainer}>
                    <div className={styles.statsItemIconWrapper}>
                      <DownloadIcon />
                    </div>
                    <div className={styles.statsItemContentContainer}>
                      <span className={styles.statsItemTitle}>Downloaded</span>
                      <span className={styles.statsItemCounter}>
                        {candidate?.referal?.download}{" "}
                        {candidate?.referal?.download === 1 ? "user" : "users"}
                      </span>
                    </div>
                  </div>
                  <div className={styles.statsDevider} />
                  <div className={styles.statsItemContainer}>
                    <div className={styles.statsItemIconWrapper}>
                      <UserPlusIcon />
                    </div>
                    <div className={styles.statsItemContentContainer}>
                      <span className={styles.statsItemTitle}>Sign up</span>
                      <span className={styles.statsItemCounter}>
                        {candidate?.referal?.signUp}{" "}
                        {candidate?.referal?.signUp === 1 ? "user" : "users"}
                      </span>
                    </div>
                  </div>
                  <div className={styles.statsDevider} />
                  <div className={styles.statsItemContainer}>
                    <div className={styles.statsItemIconWrapper}>
                      <GiftIcon />
                    </div>
                    <div className={styles.statsItemContentContainer}>
                      <span className={styles.statsItemTitle}>Available</span>
                      <span className={styles.statsItemCounter}>
                        {candidate?.referal?.available}{" "}
                        {candidate?.referal?.available === 1 ? "user" : "users"}
                      </span>
                    </div>
                  </div>
                  <div className={styles.statsDevider} />

                  <div className={styles.statsItemContainer}>
                    <div className={styles.statsItemContentContainer}>
                      <span className={styles.statsItemTitle}>Requested</span>
                      <span
                        style={{ color: "rgba(125, 74, 251, 1)" }}
                        className={styles.statsItemCounter}
                      >
                        {candidate?.referal?.requested} SAR
                      </span>
                    </div>
                  </div>
                  <div className={styles.payButton}>
                    <Button
                      title="Pay"
                      size="small"
                      styleType={
                        !candidate?.referal?.requested ? "disabled" : "filled"
                      }
                      disabled={!candidate?.referal?.requested}
                      onClick={() => setIsShowPaidModal(true)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tabWrapper}>
                <Button
                  onClick={() => setCurrentTab("Referrals")}
                  size="x-small"
                  title="Referrals"
                  styleType={currentTab === "Referrals" ? "filled" : "inactive"}
                />
                <Button
                  onClick={() => setCurrentTab("Transactions")}
                  size="x-small"
                  title="Transactions"
                  styleType={
                    currentTab === "Transactions" ? "filled" : "inactive"
                  }
                />
              </div>
            </>
          )}
          {!isLoading && currentTab === "Referrals" && (
            <RefferalsList candidateId={params?.id || ""} />
          )}
          {!isLoading && currentTab === "Transactions" && (
            <TransactionsList id={params?.id} />
          )}
        </div>
      </div>
    </>
  );
};

export default ReferralProfile;
