import { FC } from "react";
import { IEmployer } from "../../../../models";
import styles from "./styles.module.scss";

interface Props {
  employer: IEmployer | undefined;
}

const About: FC<Props> = ({ employer }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <h5>About the company</h5>
      <p className={styles.description}>{employer?.about}</p>
      {employer?.webPage && (
        <>
          <h5>Website</h5>
          <a href={employer.webPage} target="_blank" rel="noreferrer">
            {employer.webPage}
          </a>
        </>
      )}

      <h5>Industry</h5>
      <p className={styles.description}>{employer?.industry}</p>
      <h5>Company size</h5>
      <p className={styles.description}>{employer?.size}</p>

      <h5>Location</h5>
      <p className={styles.description}>{employer?.location}</p>
      <h5>Email</h5>
      <p className={styles.description}>{employer?.email}</p>
      {employer?.phone && (
        <>
          <h5>Profile Phone Number</h5>
          <p className={styles.description}>{employer?.phone}</p>
        </>
      )}
      {employer?.verifyRequest?.phone && (
        <>
          <h5>Business Phone Number (verified)</h5>
          <p className={styles.description}>{employer?.verifyRequest?.phone}</p>
        </>
      )}
    </div>
  );
};

export default About;
