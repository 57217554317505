import { FC, useState } from "react";
import CheckBox from "../../../Checkbox";
import Tag from "../../../Tag";
import PlaceholderAvatar from "../../../../assets/images/avatar.png";
import {
  ArrowSmallDownIcon,
  CheckBigIcon,
  CheckIcon,
  CrossIcon,
  PlaySquareIcon,
} from "../../../../assets";
import styles from "./styles.module.scss";
import { format } from "date-fns";
import Button from "../../../Button";
import { useNavigate } from "react-router-dom";

interface Props {
  item: {
    createdAt: string;
    firstName: string;
    id: string;
    lastName: string;
    location: string;
    image: string;
  };
}

const ReferealItem: FC<Props> = ({ item }): JSX.Element => {
  const navigate = useNavigate();

  return (
    <>
      <div
        onClick={() => navigate(`/candidates/${item?.id}`)}
        className={styles.wrapper}
      >
        <div className={styles.avatarWrapper}>
          <img
            src={item?.image || PlaceholderAvatar}
            alt="avatar"
            className={styles.avatar}
          />
          <p className={styles.name}>
            {item?.firstName} {item?.lastName}
          </p>
        </div>
        <div className={styles.headerBlock}>
          <p className={styles.headerBlockTitle}>{item?.location}</p>
        </div>

        <div className={styles.dateWrapper}>
          <p>{format(new Date(item?.createdAt), "MMM dd, yyyy, HH:mm")} SAR</p>
          <div className={styles.payButtonWrapper}>Signed Up</div>
        </div>
      </div>
    </>
  );
};

export default ReferealItem;
