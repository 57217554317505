import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

export const getEmployers = (
  page: number,
  limit: number,
  search: string,
  sort: number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      page,
      limit,
      sort,
    };
    if (search) {
      params.value = search;
    }

    axiosInstance
      .get("employer/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getEmployerById = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`employer/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getEmployerJobs = (
  employer_id: string,
  page: number,
  limit: number,
  value: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      page,
      limit,
    };
    if (value) {
      params.value = value;
    }
    axiosInstance
      .get(`job/employer/${employer_id}`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getEmployerVideos = (
  employer_id: string,
  page: number,
  limit: number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      page,
      limit,
    };
    axiosInstance
      .get(`video/employer/${employer_id}`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteEmployer = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`employer/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const blockEmployer = (
  employer_id: string,
  reason: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      employer_id,
      reason,
    };
    axiosInstance
      .post("employer/block", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
export const searchEmployerChats = (
  page: number,
  limit: number,
  sort: -1 | 1,
  search: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      page,
      limit,
      sort,
    };

    if (search) {
      params.value = search;
    }
    axiosInstance
      .get("chat/employer/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getEmployerChats = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`chat/employer/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
