import { format } from "date-fns";
import { FC, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { ArrowSmallDownIcon, CalendarIcon, CrossIcon } from "../../assets";
import { CvItem, IconTag, UserPreview } from "../../components";
import { IApplicant } from "../../models";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  item: IApplicant;
}

const Application: FC<Props> = ({ isOpen, setIsOpen, item }): JSX.Element => {
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <h5 className={styles.title}>Application</h5>
        <CrossIcon
          onClick={() => setIsOpen(false)}
          className={styles.closeBtn}
        />
        <header className={styles.header}>
          <div className={styles.infoBlock}>
            <div className={styles.titleRow}>
              <h4 className={styles.name}>{item.job.title}</h4>
              <div className={styles.showJobBtn}>
                <span>Show Job Posting</span>
                <ArrowSmallDownIcon />
              </div>
            </div>

            <div className={styles.userRow}>
              <UserPreview
                onClick={() => navigate(`/companies/${item?.employer?.id}`)}
                title={item?.employer?.company}
                image={item?.employer?.image}
                verified={item?.employer?.verificationStatus === "Approved"}
              />
              <IconTag
                icon={<CalendarIcon />}
                title={format(new Date(item.addAt), "LLL d, yyyy")}
              />
            </div>
          </div>
        </header>
        <div className={styles.tagsContainer}>
          <div className={styles.calendar}></div>
        </div>
        <section className={styles.contentContainer}>
          <div className={styles.descriptionBlock}>
            <p className={styles.sectionTitle} style={{ marginTop: 0 }}>
              Candidate
            </p>
            <UserPreview
              onClick={() => navigate(`/candidates/${item?.candidate?.id}`)}
              title={`${item.candidate?.firstName} ${item?.candidate?.lastName}`}
              image={item?.candidate?.image}
              verified={false}
            />
            {/* <p className={styles.sectionTitle}>Tags</p>
            <div className={styles.tagsContainer}>
              {[1, 2, 2, 2, 2, 2].map((item, index) => (
                <Tag key={index} title="Salesperson" />
              ))}
            </div> */}
            <p className={styles.sectionTitle}>CV</p>
            <div className={styles.cvWrapper}>
              <CvItem item={item.CV} />
            </div>
          </div>
          <div className={styles.videoBlock}>
            <p className={styles.sectionTitle}>Video</p>
            <video src={item.video} controls className={styles.video}></video>
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default Application;
