import { FC, ReactNode } from "react";
import styles from "./styles.module.scss";

interface Props {
  icon: ReactNode;
  title: string;
}

const IconTag: FC<Props> = ({ icon, title }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      {icon}
      <span>{title}</span>
    </div>
  );
};

export default IconTag;
