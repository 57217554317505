import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

export const getJobs = (
  page: number,
  limit: number,
  search: string,
  sort: number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      page,
      limit,
      sort,
    };
    if (search) {
      params.value = search;
    }

    axiosInstance
      .get("job/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteJob = (
  id: string,
  reason: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`job/${id}/${reason}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getJobById = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`job/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.repsonse?.data));
  });
};

export const getApplicants = (
  page: number,
  limit: number,
  search: string,
  sort: number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      page,
      limit,
      sort,
    };
    if (search) {
      params.search = search;
    }

    axiosInstance
      .get("job/applicants", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
