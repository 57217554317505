import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

export const getBusinessVerificationRequests = (
  search: string,
  limit: number,
  page: number,
  sort: 1 | -1
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      search,
      page,
      limit,
      sort,
    };
    axiosInstance
      .get("employer/verify", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const approveBusinessVerificationRequest = (
  employer_id: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      employer_id,
    };
    axiosInstance
      .put("employer/verify/approve", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.reponse?.data));
  });
};

export const rejectBusinessVerificationRequest = (
  employer_id: string,
  reason: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      employer_id,
      reason,
    };

    axiosInstance
      .put("employer/verify/reject", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
