import { FC, useRef, useState } from "react";
import { AvatarIcon, DotsVertical, PencilIcon, TrashIcon } from "../../assets";
import { IStaff } from "../../models";
import Checkbox from "../Checkbox";
import styles from "./styles.module.scss";
import AvatarPlaceholder from "../../assets/images/avatar.png";
import Selector from "../Selector";
import { StaffRole } from "../../constants";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useOutsideClick } from "../../hooks";
import { ClipLoader } from "react-spinners";
import { deleteStaff } from "../../services/staff.service";
import { getUser } from "../../utils/tokenStorage";
import { Delete } from "../../modals";

interface Props {
  item: IStaff;
  onGetAllStaff: () => void;
}

const StaffItem: FC<Props> = ({ item, onGetAllStaff }): JSX.Element => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const user = getUser();

  const navigate = useNavigate();
  const ref = useRef(null);

  useOutsideClick(ref, () => setIsOpenMenu(false));

  const onDelete = async () => {
    try {
      setIsLoadingDelete(true);
      const { data } = await deleteStaff(item.id);
      if (data?.success) {
        onGetAllStaff();
      }
      setIsLoadingDelete(false);
    } catch (error) {
      setIsLoadingDelete(false);
    }
  };

  return (
    <>
      <Delete
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
        isLoading={isLoadingDelete}
        onDelete={onDelete}
      />
      <div ref={ref} className={styles.wrapper}>
        <Checkbox isChecked={false} setIsChecked={() => {}} />
        <div className={styles.nameWrapper}>
          <img
            src={item.image || AvatarPlaceholder}
            alt="avatar"
            className={styles.avatar}
          />
          <p className={styles.name}>{item?.fullName}</p>
        </div>
        <div className={styles.emailWrapper}>
          <p className={styles.email}>{item?.email}</p>
        </div>
        <div className={styles.roleWrapper}>
          <Selector
            disabled={true}
            value={item.type}
            setValue={() => {}}
            data={StaffRole}
          />
          {user?.email !== item.email && (
            <DotsVertical
              onClick={() => setIsOpenMenu((prev) => !prev)}
              className={styles.dotsMenu}
            />
          )}
        </div>
        {isOpenMenu && (
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className={styles.menuWrapper}
          >
            <div
              onClick={() => {
                setIsOpenMenu(false);
                setIsShowDeleteModal(true);
              }}
              className={styles.menuButton}
            >
              <TrashIcon className={styles.deleteIcon} width={20} height={20} />
              <span style={{ color: "#EE3758" }}>Delete</span>
            </div>
          </motion.div>
        )}
      </div>
    </>
  );
};

export default StaffItem;
