import { FC, useState } from "react";
import CheckBox from "../Checkbox";
import styles from "./styles.module.scss";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import ReportType from "../ReportType";
import { ArrowSmallDownIcon } from "../../assets";
import { JobPosting } from "../../modals";
import UserPreview from "../UserPreview";
import { useNavigate } from "react-router-dom";
import { ICandidate } from "../../models";
import { format } from "date-fns";

interface Props {
  item: ICandidate;
}

const CandidateItem: FC<Props> = ({ item }): JSX.Element => {
  const navigate = useNavigate();

  return (
    <>
      <div
        onClick={() => navigate(`/candidates/${item.id}`)}
        className={styles.wrapper}
      >
        <CheckBox isChecked={false} setIsChecked={() => {}} />
        <div className={styles.avatarWrapper}>
          <UserPreview
            image={item.image}
            title={`${item.firstName} ${item.lastName}`}
            verified={false}
          />
        </div>

        <div className={styles.companyWrapper}>
          <p>{item.location}</p>
        </div>

        <div className={styles.dateWrapper}>
          <p>{format(new Date(item.createdAt), "LLL d, yyyy")}</p>

          <div className={styles.arrowBtn}>
            <ArrowSmallDownIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateItem;
