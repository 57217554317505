import { FC, useState } from "react";
import CheckBox from "../Checkbox";
import Tag from "../Tag";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import {
  ArrowSmallDownIcon,
  CheckBigIcon,
  CheckIcon,
  CrossIcon,
  PlaySquareIcon,
  VerifiedIcon,
} from "../../assets";
import styles from "./styles.module.scss";
import { BusinessVerification, Request } from "../../modals";
import { IRequest } from "../../models";
import { format } from "date-fns";
import { IBusinessVerificationItem } from "../../models/IBusinessVerificationItem";

interface Props {
  item: IBusinessVerificationItem;
  onGetVerificationRequests: () => void;
}

const BusinessVerificationItem: FC<Props> = ({
  item,
  onGetVerificationRequests,
}): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isRejected, setIsRejected] = useState(false);

  return (
    <>
      <BusinessVerification
        item={item}
        isOpen={isOpenModal}
        isRejected={isRejected}
        setIsOpen={setIsOpenModal}
        onGetVerificationRequests={onGetVerificationRequests}
      />
      <div onClick={() => setIsOpenModal(true)} className={styles.wrapper}>
        <CheckBox isChecked={false} setIsChecked={() => {}} />
        <div className={styles.avatarWrapper}>
          <img
            src={item?.image || PlaceholderCompanyAvatar}
            alt="avatar"
            className={styles.avatar}
          />
          <p className={styles.name}>{item?.company}</p>
          {item?.verificationStatus === "Approved" && (
            <VerifiedIcon style={{ marginLeft: -10 }} />
          )}
        </div>
        <div className={styles.tagsWrapper}>
          <p className={styles.phone}>{item?.phone}</p>
        </div>

        <div className={styles.dateWrapper}>
          <p>
            {format(
              new Date(item?.verificationRequest?.createdAt),
              "LLL d, yyyy"
            )}
          </p>

          <div className={styles.btnWrapper}>
            <div
              onClick={() => {
                setIsRejected(false);
                setIsOpenModal(true);
              }}
              className={styles.approveBtn}
            >
              <CheckBigIcon />
            </div>

            <div
              onClick={() => {
                setIsRejected(true);
                setIsOpenModal(true);
              }}
              className={styles.declineBtn}
            >
              <CrossIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessVerificationItem;
