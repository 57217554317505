import clsx from "clsx";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { ArrowSmallDownIcon } from "../../../assets";
import {
  Button,
  CheckBox,
  PageItem,
  ReferralItem,
  RequestItem,
  SearchInput,
} from "../../../components";
import { IRequest } from "../../../models";
import { getRequests } from "../../../services/request.service";
import styles from "./styles.module.scss";
import { searchReferral } from "../../../services/referral.service";
import { useDebounce } from "../../../hooks";
import PaginationButtons from "../../../components/PaginationButtons";
import { IReferral } from "../../../models/IReferral";

const limit = 20;

const Referrals: FC = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<"Candidate" | "Employer">(
    "Candidate"
  );
  const [search, setSearch] = useState<string>("");
  const debounceSearch = useDebounce(search, 300);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [referrals, setReferrals] = useState<IReferral[]>([]);
  const [sort, setSort] = useState<1 | -1>(-1);
  const page = useRef<number>(1);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetRequests = async () => {
    try {
      setIsLoading(true);

      const { data } = await searchReferral(search, page.current, limit, sort);
      if (data?.success) {
        setPagesCount(Math.ceil(data?.count / limit));
        setReferrals(data?.candidates);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetRequests();
  }, [debounceSearch, sort]);

  return (
    <>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>Referrals</h4>
        </header>
        <section className={styles.contentContainer}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Search"
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div className={styles.sectionHeader}>
            <div className={styles.referralsBlock}>
              <p>Referral</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.headerBlock}>
              <p>Location</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.headerBlock}>
              <p>Download</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.headerBlock}>
              <p>Sign up</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.headerBlock}>
              <p>Paid</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.headerBlock}>
              <p>Available</p> <ArrowSmallDownIcon />
            </div>

            <div
              onClick={() => setSort((prev) => (prev === 1 ? -1 : 1))}
              className={clsx(styles.dateBlock, {
                [styles.dateBlockActive]: sort === 1,
              })}
            >
              <p>Requested</p> <ArrowSmallDownIcon />
            </div>
          </div>
          <div className={styles.reportsContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#7D4AFB"} />
              </div>
            )}
            {!isLoading &&
              referrals.map((item, index) => {
                return (
                  <ReferralItem
                    onGetReferrals={onGetRequests}
                    item={item}
                    key={index}
                  />
                );
              })}
            {!isLoading && referrals?.length === 0 && (
              <div className={styles.emptyTitle}>There are no new requests</div>
            )}
          </div>
          <PaginationButtons
            pagesCount={pagesCount}
            page={page.current}
            onPress={(index) => {
              page.current = index;
              onGetRequests();
            }}
          />
        </section>
      </div>
    </>
  );
};

export default Referrals;
