import { FC, useState } from "react";
import CheckBox from "../Checkbox";
import styles from "./styles.module.scss";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import ReportType from "../ReportType";
import { ArrowSmallDownIcon } from "../../assets";
import { Application, JobPosting } from "../../modals";
import UserPreview from "../UserPreview";
import { IApplicant } from "../../models";
import { format } from "date-fns";

interface Props {
  item: IApplicant;
}

const ApplicationItem: FC<Props> = ({ item }): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  return (
    <>
      <Application
        item={item}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
      />
      <div onClick={() => setIsOpenModal(true)} className={styles.wrapper}>
        <CheckBox isChecked={false} setIsChecked={() => {}} />
        <div className={styles.avatarWrapper}>
          <img
            src={
              item.candidate.image ? item.candidate.image : PlaceholderAvatar
            }
            alt="avatar"
            className={styles.avatar}
          />
          <p className={styles.name}>
            {item.candidate.firstName} {item.candidate.lastName}
          </p>
        </div>

        <div className={styles.companyWrapper}>
          <UserPreview
            title={item?.employer?.company}
            image={item?.employer?.image}
            verified={item?.employer?.verificationStatus === "Approved"}
          />
        </div>

        <div className={styles.workWrapper}>
          <p>{item.job.title}</p>
        </div>

        <div className={styles.dateWrapper}>
          <p>{format(new Date(item.addAt), "LLL d, yyyy")}</p>

          <div className={styles.arrowBtn}>
            <ArrowSmallDownIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationItem;
