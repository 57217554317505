import axiosInstance from "../apis";
import { AxiosResponse } from "axios";

export const getAllStaff = async (): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get("admin/all")
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createStaff = (
  email: string,
  password: string,
  fullName: string,
  type: string,
  image: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {
      email,
      password,
      fullName,
      type,
    };
    if (image) {
      body.image = image;
    }
    axiosInstance
      .post("auth/create", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteStaff = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
