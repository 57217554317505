import axiosInstance from "../apis";
import axios from "axios";
export const openLink = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

export const fileDownloader = async (url: string, fileName: string) => {
  await axios({
    url: url,
    method: "GET",
    headers: {
      "Content-Type": "",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      return response;
    })
    .catch((e) => e);
};
