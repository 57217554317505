import clsx from "clsx";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { ArrowSmallDownIcon } from "../../../assets";
import {
  Button,
  CheckBox,
  PageItem,
  RequestItem,
  SearchInput,
  CandidateMessageItem,
} from "../../../components";
import { IRequest } from "../../../models";
import { getRequests } from "../../../services/request.service";
import styles from "./styles.module.scss";
import { searchCandidateChats } from "../../../services/candidate.service";
import { useDebounce } from "../../../hooks";
import { searchEmployerChats } from "../../../services/employer.service";
import PaginationButtons from "../../../components/PaginationButtons";
import EmployerMessageItem from "../../../components/EmployerMessageItem";

const limit = 20;

const Messages: FC = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<"Candidate" | "Employer">(
    "Employer"
  );
  const [search, setSearch] = useState<string>("");
  const debounceSearch = useDebounce(search, 300);
  const page = useRef(1);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chats, setChats] = useState<IRequest[]>([]);
  const [sort, setSort] = useState<1 | -1>(-1);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    page.current = 1;
    setSearch(e.target.value);
  };

  const onGetChats = async (activeTab: "Candidate" | "Employer") => {
    try {
      setIsLoading(true);

      if (activeTab === "Candidate") {
        const { data } = await searchCandidateChats(
          page.current,
          limit,
          sort,
          debounceSearch
        );
        if (data?.success) {
          setPagesCount(Math.ceil(data?.count / limit));
          setChats(data?.candidates);
        }
      } else {
        const { data } = await searchEmployerChats(
          page.current,
          limit,
          sort,
          debounceSearch
        );
        if (data?.success) {
          setPagesCount(Math.ceil(data?.count / limit));
          setChats(data?.employers);
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetChats(activeTab);
  }, [debounceSearch, activeTab, sort]);

  return (
    <>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>Messages</h4>
        </header>
        <section className={styles.contentContainer}>
          <div className={styles.sortSwitchersWrapper}>
            <Button
              title={"Companies"}
              size={"x-small"}
              onClick={() => {
                page.current = 1;
                setActiveTab("Employer");
              }}
              styleType={activeTab == "Employer" ? "filled" : "inactive"}
            />
            <Button
              title={"Candidates"}
              size={"x-small"}
              onClick={() => {
                page.current = 1;

                setActiveTab("Candidate");
              }}
              styleType={activeTab == "Candidate" ? "filled" : "inactive"}
            />
          </div>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Search"
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div className={styles.sectionHeader}>
            <CheckBox isChecked={false} setIsChecked={() => {}} />

            <div className={styles.tagsBlock}>
              <p>{activeTab === "Candidate" ? "Candidate" : "Company name"}</p>{" "}
              <ArrowSmallDownIcon />
            </div>
            {activeTab === "Employer" && (
              <div className={styles.reporterBlock}>
                <p>Industry</p> <ArrowSmallDownIcon />
              </div>
            )}
            <div className={styles.reporterBlock}>
              <p>Location</p> <ArrowSmallDownIcon />
            </div>
            {activeTab === "Employer" && (
              <div className={styles.reporterBlock}>
                <p>User Name</p> <ArrowSmallDownIcon />
              </div>
            )}

            <div
              onClick={() => setSort((prev) => (prev === 1 ? -1 : 1))}
              className={clsx(styles.dateBlock, {
                [styles.dateBlockActive]: sort === 1,
              })}
            >
              <p>Request date</p> <ArrowSmallDownIcon />
            </div>
          </div>
          <div className={styles.reportsContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#7D4AFB"} />
              </div>
            )}
            {!isLoading &&
              chats.map((item, index) => {
                if (activeTab === "Candidate") {
                  return <CandidateMessageItem item={item} key={item?.id} />;
                }
                return <EmployerMessageItem item={item} key={item?.id} />;
              })}
            {!isLoading && chats?.length === 0 && (
              <div className={styles.emptyTitle}>There are no new messages</div>
            )}
          </div>
          <PaginationButtons
            pagesCount={pagesCount}
            page={page.current}
            onPress={(index) => {
              page.current = index;
              onGetChats(activeTab);
            }}
          />
        </section>
      </div>
    </>
  );
};

export default Messages;
