import { FC, useState } from "react";
import CheckBox from "../Checkbox";
import styles from "./styles.module.scss";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import ReportType from "../ReportType";
import { ArrowSmallDownIcon } from "../../assets";
import { Report } from "../../modals";
import { IEmloyment } from "../../models";

interface Props {
  item: IEmloyment;
}

const EmploymentItem: FC<Props> = ({ item }): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  return (
    <>
      <div onClick={() => setIsOpenModal(true)} className={styles.wrapper}>
        <div className={styles.avatarWrapper}>
          <img
            src={item.image ? item?.image : PlaceholderCompanyAvatar}
            alt="avatar"
            className={styles.avatar}
          />
          <p className={styles.name}>{item.company}</p>
        </div>

        <div className={styles.sizeWrapper}>
          <p>{item.role}</p>
        </div>
        <div className={styles.dateWrapper}>
          <p>{item.term}</p>
        </div>
      </div>
    </>
  );
};

export default EmploymentItem;
