import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowLineIcon,
  ArrowSmallDownIcon,
  InstagramIcon,
  LinkedInIcon,
  VerifiedIcon,
} from "../../../assets";
import {
  Button,
  CvItem,
  EducationItem,
  EmploymentItem,
  SearchInput,
} from "../../../components";
import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../../assets/images/companyAvatar.png";
import VideoItem from "../../../components/VideoItem";
import { IEmployer } from "../../../models";
import {
  deleteEmployer,
  getEmployerById,
} from "../../../services/employer.service";
import { PulseLoader } from "react-spinners";
import JobPosting from "./JobPosting";
import Video from "./Video";
import About from "./About";
import { openLink } from "../../../utils";
import { BlockUser, Delete } from "../../../modals";

interface Props {}

const CompanyProfile: FC<Props> = (): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<
    "About" | "Videos" | "JobPosting"
  >("JobPosting");
  const [search, setSearch] = useState<string>("");
  const [employer, setEmployer] = useState<IEmployer>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowBlockModal, setIsShowBlockModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetEmployer = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getEmployerById(id);
      if (data?.success) {
        setEmployer(data?.employer);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onDeleteEmployer = async () => {
    try {
      setIsLoadingDelete(true);
      const { data } = await deleteEmployer(params?.id || "");
      if (data?.success) {
        navigate(-1);
      }
      setIsLoadingDelete(false);
    } catch (error) {
      setIsLoadingDelete(false);
    }
  };

  // const onDeleteEmployer = async () => {
  //   try {
  //     setIsLoadingDelete(true);
  //     const { data } = await deleteEmployer(params?.id || "");
  //     if (data?.success) {
  //       navigate(-1);
  //     }
  //     setIsLoadingDelete(false);
  //   } catch (error) {
  //     setIsLoadingDelete(false);
  //   }
  // };

  useEffect(() => {
    if (params.id) {
      onGetEmployer(params.id);
    }
  }, [params]);

  return (
    <>
      <BlockUser
        type={"employer"}
        userId={employer?.id || ""}
        isOpen={isShowBlockModal}
        setIsOpen={setIsShowBlockModal}
      />
      <Delete
        onDelete={onDeleteEmployer}
        isLoading={isLoadingDelete}
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
      />
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div onClick={() => navigate(-1)} className={styles.backBtn}>
            <ArrowLineIcon />
            <span>Company Profile</span>
          </div>
          <div className={styles.btnWrapper}>
            <Button
              title={"Block"}
              size="small"
              styleType="outlined"
              onClick={() => setIsShowBlockModal(true)}
              style={{ border: "none", width: "87px", background: "#E4E4E4" }}
            />
            <Button
              title={"Delete"}
              size="small"
              styleType="filled"
              onClick={() => setIsShowDeleteModal(true)}
              loading={isLoadingDelete}
              style={{ border: "none", width: "87px", background: "#FF3C3C" }}
            />
          </div>
        </div>
        <div className={styles.contentContainer}>
          {isLoading ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          ) : (
            <>
              <div className={styles.header}>
                <div className={styles.info}>
                  <img
                    src={employer?.image ? employer?.image : PlaceholderAvatar}
                    alt="avatar"
                    className={styles.avatar}
                  />
                  <div className={styles.nameWrapper}>
                    <p className={styles.name}>
                      {employer?.company}
                      {employer?.verificationStatus === "Approved" && (
                        <VerifiedIcon
                          style={{ marginLeft: 10, width: 18, height: 18 }}
                        />
                      )}
                    </p>
                    <p className={styles.location}>{employer?.industry}</p>
                  </div>
                </div>
                <div className={styles.followersStatsWrapper}>
                  <div className={styles.block}>
                    <p className={styles.counter}>{employer?.favoriteCount}</p>
                    <p className={styles.subtitle}>Followers</p>
                  </div>
                  <div className={styles.divider} />
                  <div className={styles.block}>
                    <p className={styles.counter}>{employer?.jobsCount}</p>
                    <p className={styles.subtitle}>Job Postings</p>
                  </div>
                </div>
                <div className={styles.socialbtnWrapper}>
                  {employer?.linkedin && (
                    <LinkedInIcon
                      className={styles.socialBtn}
                      onClick={() => {
                        openLink(employer?.linkedin || "");
                      }}
                    />
                  )}
                  {employer?.instagram && (
                    <InstagramIcon
                      className={styles.socialBtn}
                      onClick={() => {
                        openLink(
                          `https://www.instagram.com/${employer?.instagram?.slice(
                            1
                          )}`
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={styles.tabWrapper}>
                <Button
                  onClick={() => setCurrentTab("JobPosting")}
                  size="x-small"
                  title="Job Posting"
                  styleType={
                    currentTab === "JobPosting" ? "filled" : "inactive"
                  }
                />
                <Button
                  onClick={() => setCurrentTab("Videos")}
                  size="x-small"
                  title="Video"
                  styleType={currentTab === "Videos" ? "filled" : "inactive"}
                />
                <Button
                  onClick={() => setCurrentTab("About")}
                  size="x-small"
                  title="About"
                  styleType={currentTab === "About" ? "filled" : "inactive"}
                />
              </div>
              {currentTab === "JobPosting" ? (
                <JobPosting employerId={employer?.id || ""} />
              ) : currentTab === "Videos" ? (
                <Video employer={employer} />
              ) : (
                <About employer={employer} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
