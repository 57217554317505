import axiosInstance from "../apis";
import { AxiosResponse } from "axios";

export const getReports = (
  page: number,
  limit: number,
  search: string,
  categories: string[],
  types: string[],
  sort: number,
  type: "All" | "Candidates" | "Companies"
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      page,
      limit,
      sort,
    };
    if (search) {
      params.search = search;
    }
    if (categories?.length > 0) {
      params.categories = categories.join(",");
    }
    if (types?.length > 0) {
      params.types = types.join(",");
    }
    let url =
      type === "All"
        ? "report"
        : type === "Candidates"
        ? "report/candidate"
        : "report/employer";

    axiosInstance
      .get(url, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const skipReport = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`report/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
