import { AxiosResponse } from "axios";
import axiosInstance from "../apis";
import { getRefreshToken } from "../utils/tokenStorage";

export const login = (
  email: string,
  password: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      email,
      password,
    };

    axiosInstance
      .post("auth/login", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const logout = (): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const refreshToken = getRefreshToken();
    const body = {
      refreshToken,
    };
    axiosInstance
      .post("auth/logout", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getNewAccessToken = (): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const refreshToken = getRefreshToken();
    const body = {
      refreshToken,
    };
    axiosInstance
      .post("auth/refresh", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getLinkForUploadImage = (
  fileName: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      fileName,
    };
    axiosInstance
      .post("auth/image", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
