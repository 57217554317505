import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { CrossIcon, UserIcon } from "../../assets";
import { Button, Input, Selector } from "../../components";
import { StaffRole } from "../../constants";
import { getLinkForUploadImage } from "../../services/auth.service";
import { uploadFile } from "../../services/file.service";
import { createStaff } from "../../services/staff.service";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onGetAllStaff: () => void;
}

const AddNewStaff: FC<Props> = ({
  isOpen,
  setIsOpen,
  onGetAllStaff,
}): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [role, setRole] = useState<string>("Admin");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<string>("");
  const [avatarFile, setAvatarFile] = useState<
    ArrayBuffer | null | undefined | string | any
  >();
  const [avatarFileName, setAvatarFileName] = useState<string>("");

  //error
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [fullNameError, setFullNameError] = useState<string>("");

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const onChangeFullName = (e: ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value);
  };
  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (email?.length > 3 && password && fullName) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [email, password, fullName]);

  const onCreateStaff = async () => {
    try {
      setIsLoading(true);
      let imageLink = "";
      if (avatarFile) {
        //@ts-ignore
        const resLinkImg = await getLinkForUploadImage(avatarFileName);
        imageLink = resLinkImg?.data?.downloadUrl;
        let blob = await fetch(avatar).then((r) => r.blob());
        const resUploadImg = await uploadFile(
          blob,
          resLinkImg?.data?.uploadUrl
        );
      }

      const { data } = await createStaff(
        email,
        password,
        fullName,
        role,
        imageLink
      );
      if (data?.success) {
        onGetAllStaff();
        setIsOpen(false);
        setEmailError("");
        setPasswordError("");
        setFullNameError("");
        setEmail("");
        setFullName("");
        setPassword("");
        setAvatar("");
        setAvatarFile(undefined);
        setAvatarFileName("");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      if (error?.field === "email") {
        setEmailError(error?.message);
      } else {
        setEmailError("");
      }
      if (error?.field === "password") {
        setPasswordError(error?.message);
      } else {
        setPasswordError("");
      }
      if (error?.field === "fullName") {
        setFullNameError(error?.message);
      } else {
        setFullNameError("");
      }
    }
  };

  const inputFile = useRef<HTMLInputElement>(null);

  const showOpenFileDialog = () => {
    inputFile.current?.click();
  };
  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      setAvatar(URL.createObjectURL(e.target.files[0]));
      let file = e.target.files[0];
      setAvatarFile(e.target.files[0]);

      setAvatarFileName(file?.name);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <h5 className={styles.title}>New Manager</h5>
        <CrossIcon
          onClick={() => setIsOpen(false)}
          className={styles.closeBtn}
        />
        <div className={styles.header}>
          <div onClick={showOpenFileDialog} className={styles.avatarBtn}>
            {avatar && (
              <img src={avatar} alt={"avatar"} className={styles.avatar} />
            )}
            {!avatar && <UserIcon />}
            {/* <img src={AvatarIcon} alt={} /> */}
          </div>

          <input
            style={{ display: "none" }}
            ref={inputFile}
            type={"file"}
            accept="image/*"
            onChange={onSelectFile}
            onClick={(e: any) => (e.target.value = null)}
            className={styles.bootcampsSettingsUploadImgBtn}
          />
          <p className={styles.avatarTitle}>Change Avatar</p>
        </div>

        <div className={styles.inputsContainer}>
          <Input
            label="Full Name"
            value={fullName}
            onChange={onChangeFullName}
            placeholder="Full Name"
            autoComplete="off"
            error={fullNameError}
          />
          <Input
            label="Email"
            value={email}
            onChange={onChangeEmail}
            placeholder="example@gmail.com"
            autoComplete="off"
            error={emailError}
          />
          <Input
            label="Password"
            value={password}
            onChange={onChangePassword}
            placeholder="password"
            type={"password"}
            autoComplete="off"
            error={passwordError}
          />
          <Selector
            label="Role"
            value={role}
            setValue={setRole}
            data={StaffRole}
          />
        </div>
        <Button
          title="Create"
          styleType={isDisabled ? "disabled" : "filled"}
          disabled={isDisabled}
          loading={isLoading}
          onClick={onCreateStaff}
          size="small"
        />
      </div>
    </Modal>
  );
};

export default AddNewStaff;
