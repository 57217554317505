import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

export const searchPropositions = (
  page: number,
  limit: number,
  sort: -1 | 1,
  value: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      page,
      limit,
      sort,
    };
    if (value) {
      params.value = value;
    }
    axiosInstance
      .get("video/proposition/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
