import { ChangeEvent, FC, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { CrossIcon } from "../../assets";
import { Button, Radio, Textarea } from "../../components";
import { BlockReason } from "../../constants";
import { blockCandidate } from "../../services/candidate.service";
import { blockEmployer } from "../../services/employer.service";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  type: "candidate" | "employer";
  userId: string;
}

const BlockUser: FC<Props> = ({
  isOpen,
  setIsOpen,
  type,
  userId,
}): JSX.Element => {
  const [blockReason, setBlockReason] = useState<string>("Other");
  const [otherReason, setOtherReason] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onChangeOtherReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
  };

  const onBlockAccount = async () => {
    try {
      setIsLoading(true);

      if (type === "candidate") {
        const { data } = await blockCandidate(
          userId,
          blockReason === "Other" ? otherReason : blockReason
        );
        if (data?.success) {
          setIsOpen(false);
          navigate("/candidates");
        }
      } else {
        const { data } = await blockEmployer(
          userId,
          blockReason === "Other" ? otherReason : blockReason
        );
        if (data?.success) {
          navigate("/companies");

          setIsOpen(false);
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <CrossIcon
          onClick={() => setIsOpen(false)}
          className={styles.closeBtn}
        />
        <h5>Block user</h5>
        <div className={styles.optionsContainer}>
          <div className={styles.blockReasonWrapper}>
            <p className={styles.title}>Choose a reason:</p>
            <div className={styles.reasonList}>
              {BlockReason.map((item, index) => {
                return (
                  <div key={index} className={styles.radioOption}>
                    <Radio
                      isCheked={blockReason === item}
                      setIsCheked={() => setBlockReason(item)}
                    />
                    <p className={styles.radioTitle}>{item}</p>
                  </div>
                );
              })}
              {blockReason === "Other" && (
                <Textarea
                  placeholder="Message"
                  value={otherReason}
                  onChange={onChangeOtherReason}
                  maxLength={240}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button
            title={"Block"}
            size={"small"}
            loading={isLoading}
            onClick={onBlockAccount}
            disabled={blockReason === "Other" && !otherReason}
            styleType={
              blockReason === "Other" && !otherReason ? "disabled" : "filled"
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default BlockUser;
