import { format } from "date-fns";
import { FC } from "react";
import { PdfIcon } from "../../assets";
import { ICV } from "../../models";
import { fileDownloader } from "../../utils";
import styles from "./styles.module.scss";

interface Props {
  item?: ICV;
}

const CvItem: FC<Props> = ({ item }): JSX.Element => {
  const onDownLoad = async () => {
    try {
      if (item?.url) {
        await fileDownloader(item?.url, item?.fileName);
      }
    } catch (error) {}
  };

  return (
    <a href={item?.url} className={styles.wrapper}>
      <PdfIcon />
      <div className={styles.infoContainer}>
        <div className={styles.title}>{item?.fileName}</div>
        <p className={styles.date}>
          Uploaded {item?.createdAt && format(new Date(item?.createdAt), "p")}
        </p>
      </div>
    </a>
  );
};

export default CvItem;
