import { FC, useState } from "react";
import Modal from "react-modal";
import { CrossIcon } from "../../assets";
import { Button } from "../../components";
import styles from "./styles.module.scss";
import { payReferralTransaction } from "../../services/referral.service";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userName: string;
  candidateId: string | undefined;
  amount: number | undefined;
  onGetCandidate: (id: string) => void;
}

const Paid: FC<Props> = ({
  isOpen,
  setIsOpen,
  candidateId,
  userName,
  amount,
  onGetCandidate,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const onPay = async () => {
    try {
      setIsLoading(true);

      const { data } = await payReferralTransaction(
        candidateId as string,
        amount as number
      );
      if (data?.success) {
        setIsOpen(false);
        onGetCandidate(candidateId as string);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <CrossIcon
          onClick={() => setIsOpen(false)}
          className={styles.closeBtn}
        />
        <h5>{userName} requested</h5>
        <h1>{amount} SAR</h1>
        <Button
          title={"Pay"}
          loading={isLoading}
          onClick={onPay}
          size={"small"}
          styleType="filled"
        />
      </div>
    </Modal>
  );
};

export default Paid;
