import clsx from "clsx";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { ArrowSmallDownIcon } from "../../../assets";
import {
  Button,
  CheckBox,
  CompanyItem,
  PageItem,
  RequestItem,
  SearchInput,
} from "../../../components";
import { IEmployer } from "../../../models";
import { getEmployers } from "../../../services/employer.service";
import styles from "./styles.module.scss";
import PaginationButtons from "../../../components/PaginationButtons";

const limit = 20;

const Companies: FC = (): JSX.Element => {
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [employers, setEmployers] = useState<IEmployer[]>([]);
  const [sort, setSort] = useState<number>(-1);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetCompanies = async (
    page: number = 1,
    limit: number,
    search: string = ""
  ) => {
    try {
      setIsLoading(true);

      const { data } = await getEmployers(page, limit, search, sort);
      if (data?.success) {
        setPagesCount(Math.ceil(data?.count / limit));
        setEmployers(data?.employers);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        onGetCompanies(1, limit, search);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search, sort]);

  useEffect(() => {
    if (!search) {
      onGetCompanies(1, limit);
    }
  }, [search, sort]);

  return (
    <>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>All Companies</h4>
        </header>
        <section className={styles.contentContainer}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Search"
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div className={styles.sectionHeader}>
            <CheckBox isChecked={false} setIsChecked={() => {}} />
            <div className={styles.nameBlock}>
              <p>Company name</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.industryBlock}>
              <p>Industry</p> <ArrowSmallDownIcon />
            </div>

            <div className={styles.locationBlock}>
              <p>Location</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.sizeBlock}>
              <p>Size</p> <ArrowSmallDownIcon />
            </div>
            <div
              onClick={() => setSort((prev) => (prev === 1 ? -1 : 1))}
              className={clsx(styles.dateBlock, {
                [styles.dateBlockActive]: sort === 1,
              })}
            >
              <p>Registration date</p> <ArrowSmallDownIcon />
            </div>
          </div>
          <div className={styles.reportsContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#7D4AFB"} />
              </div>
            )}
            {!isLoading &&
              employers.map((item, index) => {
                return <CompanyItem key={item.id} item={item} />;
              })}
          </div>
          <PaginationButtons
            pagesCount={pagesCount}
            page={page}
            onPress={(index) => onGetCompanies(index, limit, search)}
          />
        </section>
      </div>
    </>
  );
};

export default Companies;
