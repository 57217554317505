import { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { CalendarIcon, ClockIcon, CrossIcon, SuitCaseIcon } from "../../assets";
import {
  Button,
  IconTag,
  Radio,
  Tag,
  Textarea,
  UserPreview,
} from "../../components";
import {
  BASE_URL_FOR_SECURE_IMAGE,
  BlockReason,
  BusinessVerificationRejectReason,
} from "../../constants";
import { IRequest } from "../../models";
import styles from "./styles.module.scss";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import { approveRequest, rejectRequest } from "../../services/request.service";
import { useNavigate } from "react-router-dom";
import { IBusinessVerificationItem } from "../../models/IBusinessVerificationItem";
import { getAccessToken } from "../../utils/tokenStorage";
import {
  approveBusinessVerificationRequest,
  rejectBusinessVerificationRequest,
} from "../../services/verification.service";
import ImageViewer from 'react-simple-image-viewer';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  item: IBusinessVerificationItem;
  onGetVerificationRequests: () => void;
  isRejected: boolean;
}

interface ISecureImageProps {
  url: string;
  employerId: string;
}

const SecureImage: FC<ISecureImageProps> = ({ url, employerId }) => {
  const [imageSource, setImageSource] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const src = `${BASE_URL_FOR_SECURE_IMAGE}${employerId}/${url}`;

  const options: any = {
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
    },
  };

  useEffect(() => {
    fetch(src, options)
      .then((res) => res.blob())
      .then((blob) => {
        setImageSource(URL.createObjectURL(blob));
      });
  }, []);

  if (!imageSource) {
    return <div className={styles.image} />;
  }

  return <><img onClick={() => setIsViewerOpen(true)} src={imageSource} className={styles.image} />
    {isViewerOpen && (
        <ImageViewer
            src={[imageSource] }
            currentIndex={0}
            backgroundStyle={{backgroundColor:"rgba(0,0,0,0.6)"}}
            disableScroll={ true }
            closeOnClickOutside={ true }
            onClose={ () => setIsViewerOpen(false) }
        />
    )}</>;
};

const Request: FC<Props> = ({
  isOpen,
  setIsOpen,
  item,
  onGetVerificationRequests,
  isRejected,
}): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<"Approve" | "Reject">(
    isRejected ? "Reject" : "Approve"
  );
  const [blockReason, setBlockReason] = useState<string>("Other");
  const [otherReason, setOtherReason] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setSelectedOption(isRejected ? "Reject" : "Approve");
  }, [isRejected, isOpen]);

  const onChangeOtherReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
  };

  const navigate = useNavigate();

  const onApproveVideo = async () => {
    try {
      setIsLoading(true);
      const { data } = await approveBusinessVerificationRequest(item?.id);
      if (data?.success) {
        onGetVerificationRequests();
        setIsOpen(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onRejectVideo = async () => {
    try {
      setIsLoading(true);
      const { data } = await rejectBusinessVerificationRequest(
        item?.id,
        blockReason === "Other" ? otherReason : blockReason
      );
      if (data?.success) {
        onGetVerificationRequests();
        setIsOpen(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const openProfile = (type: string, id: string) => {
    if (type === "Candidate") {
      navigate(`/candidates/${id}`);
    } else if (type === "Employer") {
      navigate(`/companies/${id}`);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <UserPreview
          onClick={() => openProfile("Employer", item?.id)}
          image={item?.image || PlaceholderCompanyAvatar}
          title={item?.company}
          verified={item?.verificationStatus === "Approved"}
        />
        <CrossIcon
          onClick={() => setIsOpen(false)}
          className={styles.closeBtn}
        />

        <div className={styles.contentContainer}>
          <div className={styles.videoWrapper}>
            <p className={styles.sectionTitle}>Legal business name</p>
            <p className={styles.title}>
              {item?.verificationRequest?.legalName}
            </p>
            <p className={styles.sectionTitle}>Country of region</p>
            <p className={styles.title}>{item?.verificationRequest?.country}</p>
            <p className={styles.sectionTitle}>Business address</p>
            <p className={styles.title}>{item?.verificationRequest?.address}</p>
            <p className={styles.sectionTitle}>Province or state</p>
            <p className={styles.title}>{item?.verificationRequest?.state}</p>
            <p className={styles.sectionTitle}>City</p>
            <p className={styles.title}>{item?.verificationRequest?.city}</p>
            <p className={styles.sectionTitle}>Phone</p>
            <p className={styles.title}>{item?.verificationRequest?.phone}</p>
            <p className={styles.sectionTitle}>Business qualification</p>
            <div className={styles.imagesContainer}>
              {item?.verificationRequest?.files.map((url, index) => {
                return (
                  <SecureImage key={index} url={url} employerId={item?.id} />
                );
              })}
            </div>
          </div>
          <div className={styles.infoWrapper}>
            <div className={styles.optionsContainer}>
              <div className={styles.radioOption}>
                <Radio
                  isCheked={selectedOption === "Approve"}
                  setIsCheked={() => setSelectedOption("Approve")}
                />
                <p className={styles.radioTitle}>Accept verification</p>
              </div>
              <div className={styles.radioOption}>
                <Radio
                  isCheked={selectedOption === "Reject"}
                  setIsCheked={() => setSelectedOption("Reject")}
                />
                <p className={styles.radioTitle}>Reject verification</p>
              </div>
              {selectedOption === "Reject" && (
                <div className={styles.blockReasonWrapper}>
                  <p className={styles.title}>Reason of rejection:</p>
                  <div className={styles.reasonList}>
                    {BusinessVerificationRejectReason.map((item, index) => {
                      return (
                        <div key={index} className={styles.radioOption}>
                          <Radio
                            isCheked={blockReason === item}
                            setIsCheked={() => setBlockReason(item)}
                          />
                          <p className={styles.radioTitle}>{item}</p>
                        </div>
                      );
                    })}
                    {blockReason === "Other" && (
                      <Textarea
                        placeholder="Message"
                        value={otherReason}
                        onChange={onChangeOtherReason}
                        maxLength={240}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            <Button
              onClick={
                selectedOption === "Approve" ? onApproveVideo : onRejectVideo
              }
              disabled={
                selectedOption === "Reject" &&
                blockReason === "Other" &&
                !otherReason
              }
              loading={isLoading}
              title={"Save"}
              size="small"
              styleType={
                selectedOption === "Reject" &&
                blockReason === "Other" &&
                !otherReason
                  ? "disabled"
                  : "filled"
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Request;
