import React, { FC } from "react";
import styles from "./styles.module.scss";
import PageItem from "../PageItem";
import { IProps } from "./types";
import ReactPaginate from "react-paginate";
import clsx from "clsx";
const PaginationButtons: FC<IProps> = ({
  pagesCount,
  page,
  onPress,
}): JSX.Element => {
  const handlePageClick = (event: any) => {
    onPress(event.selected + 1);
  };

  const isDisabledNext = page === pagesCount ? true : false;

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={"Next"}
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      pageCount={pagesCount}
      forcePage={page - 1}
      renderOnZeroPageCount={null}
      className={styles.paginationContainer}
      pageClassName={styles.wrapper}
      activeClassName={clsx(styles.wrapper, styles.activeWrapper)}
      pageLinkClassName={styles.text}
      activeLinkClassName={clsx(styles.text, styles.activeText)}
      nextClassName={isDisabledNext ? styles.nextBtnDisabled : styles.nextBtn}
      nextLinkClassName={styles.nextText}
      previousClassName={styles.prevBtn}
      breakClassName={styles.break}
    />
  );
};

export default PaginationButtons;
