import { ChangeEvent, FC, useState } from "react";
import Modal from "react-modal";
import { CalendarIcon, ClockIcon, CrossIcon, SuitCaseIcon } from "../../assets";
import {
  Button,
  IconTag,
  Radio,
  Tag,
  Textarea,
  UserPreview,
} from "../../components";
import { BlockReason } from "../../constants";
import { IRequest } from "../../models";
import styles from "./styles.module.scss";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import { approveRequest, rejectRequest } from "../../services/request.service";
import { useNavigate } from "react-router-dom";
import { IPropositions } from "../../models/IPropositions";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  item: IPropositions;
}

const Influencers: FC<Props> = ({ isOpen, setIsOpen, item }): JSX.Element => {
  const navigate = useNavigate();

  const openProfile = (type: string, id: string) => {
    if (type === "Candidate") {
      navigate(`/candidates/${id}`);
    } else if (type === "Employer") {
      navigate(`/companies/${id}`);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <UserPreview
          onClick={() =>
            openProfile("Candidate", item?.candidate_id || item?.employer_id)
          }
          verified={item?.employer?.verificationStatus === "Approved"}
          image={item.candidate?.image || item?.employer?.image}
          title={
            item?.candidate
              ? `${item?.candidate?.firstName} ${item?.candidate?.lastName}`
              : item.employer?.company
          }
        />
        <CrossIcon
          onClick={() => setIsOpen(false)}
          className={styles.closeBtn}
        />

        <div className={styles.contentContainer}>
          <div className={styles.videoWrapper}>
            <video src={item.url} controls className={styles.video}></video>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Influencers;
