import { FC } from "react";
import styles from "./styles.module.scss";
import { Line } from "rc-progress";
interface Props {
  title: string;
  percent: number;
}

const TagStatsItem: FC<Props> = ({ title, percent }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        <p className={styles.percent}>{percent}%</p>
      </div>
      <Line
        percent={percent}
        strokeWidth={3}
        trailWidth={3}
        trailColor="#E4E4E432"
        strokeColor="#7d4afb"
      />
    </div>
  );
};

export default TagStatsItem;
