import { FC, useRef, useState } from "react";
import { ArrowDownIcon } from "../../assets";
import styles from "./styles.module.scss";
import { motion } from "framer-motion";
import { useOutsideClick } from "../../hooks";
import clsx from "clsx";

interface Props {
  label?: string;
  value: string;
  setValue: (value: string) => void;
  data: string[];
  disabled?: boolean;
}

const Selector: FC<Props> = ({
  label = "",
  value,
  setValue,
  data,
  disabled = false,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => setIsOpen(false));

  const onSelectValue = (item: string) => {
    setValue(item);
    setIsOpen(false);
  };

  return (
    <div ref={ref} className={styles.wrapper}>
      {label && <span>{label}</span>}
      <div
        onClick={() => {
          !disabled && setIsOpen((prev) => !prev);
        }}
        className={clsx(styles.selector, { [styles.activeSelector]: isOpen })}
      >
        <p className={styles.title}>{value}</p>
        <ArrowDownIcon />
      </div>
      {isOpen && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          transition={{ duration: 0.2 }}
          className={styles.dataList}
        >
          {data?.map((item, index) => (
            <div
              key={index}
              onClick={() => onSelectValue(item)}
              className={styles.itemlist}
            >
              {item}
            </div>
          ))}
        </motion.div>
      )}
    </div>
  );
};

export default Selector;
