import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

export const getRequests = (
  page: number,
  limit: number,
  search: string,
  sort: number,
  type: "All" | "Candidate" | "Employer"
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      page,
      limit,
      sort,
    };
    if (search) {
      params.search = search;
    }
    let url =
      type === "Candidate"
        ? "video/request/candidate"
        : "video/request/employer";

    axiosInstance
      .get(url, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const approveRequest = (
  userId: string,
  userType: "Candidate" | "Employer",
  rating: number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {
      rating,
    };
    if (userType === "Candidate") {
      body.candidate_id = userId;
    } else {
      body.employer_id = userId;
    }
    const url =
      userType === "Candidate"
        ? `video/approve/candidate`
        : `video/approve/employer`;

    axiosInstance
      .put(url, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const rejectRequest = (
  userId: string,
  userType: "Candidate" | "Employer",
  reason: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {
      reason,
    };
    if (userType === "Candidate") {
      body.candidate_id = userId;
    } else if (userType === "Employer") {
      body.employer_id = userId;
    }

    axiosInstance
      .put(`video/reject/${userType.toLowerCase()}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
