import { FC, InputHTMLAttributes } from "react";
import { SearchIcon } from "../../assets";
import styles from "./styles.module.scss";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  maxWidth?: number | string;
}

const SearchInput: FC<Props> = ({ maxWidth = 447, ...props }): JSX.Element => {
  return (
    <div style={{ maxWidth }} className={styles.wrapper}>
      <SearchIcon />
      <input {...props} className={styles.input} />
    </div>
  );
};

export default SearchInput;
