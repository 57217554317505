import axiosInstance from "../apis";
import { AxiosResponse } from "axios";

export const searchReferral = (
  value: string,
  page: number,
  limit: number,
  sort: 1 | -1
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      page,
      limit,
      sort,
    };
    if (value) {
      params.value = value;
    }
    axiosInstance
      .get("candidate/referal/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getReferralTransaction = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`candidate/referal/transactions/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const payReferralTransaction = (
  candidate_id: string,
  amount: number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      candidate_id,
      amount,
    };
    axiosInstance
      .post("candidate/referal/transaction", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getCandidateReferrals = (
  candidateId: string,
  page: number,
  limit: number,
  sort: 1 | -1
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params = {
      page,
      limit,
      sort,
    };
    axiosInstance
      .get(`candidate/referal/candidates/${candidateId}`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
