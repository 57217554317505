import { FC, useState } from "react";
import CheckBox from "../Checkbox";
import styles from "./styles.module.scss";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import ReportType from "../ReportType";
import { ArrowSmallDownIcon, VerifiedIcon } from "../../assets";
import { Report } from "../../modals";
import { useNavigate } from "react-router-dom";
import { IEmployer } from "../../models";
import { format } from "date-fns";

interface Props {
  item: IEmployer;
}

const CompanyItem: FC<Props> = ({ item }): JSX.Element => {
  const navigate = useNavigate();

  return (
    <>
      <div
        onClick={() => navigate(`/companies/${item.id}`)}
        className={styles.wrapper}
      >
        <CheckBox isChecked={false} setIsChecked={() => {}} />
        <div className={styles.avatarWrapper}>
          <img
            src={item.image ? item.image : PlaceholderCompanyAvatar}
            alt="avatar"
            className={styles.avatar}
          />
          <p className={styles.name}>
            {item.company}
            {item?.verificationStatus === "Approved" && (
              <VerifiedIcon style={{ marginLeft: 5 }} />
            )}
          </p>
        </div>
        <div className={styles.industryWrapper}>
          <p>{item.industry}</p>
        </div>
        <div className={styles.locationWrapper}>
          <p>{item.location}</p>
        </div>
        <div className={styles.sizeWrapper}>
          <p>{item.size}</p>
        </div>
        <div className={styles.dateWrapper}>
          <p>{format(new Date(item.createdAt), "LLL d, yyyy")}</p>

          <div className={styles.arrowBtn}>
            <ArrowSmallDownIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyItem;
