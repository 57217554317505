import { format } from "date-fns";
import { ChangeEvent, FC, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { CalendarIcon, CrossIcon } from "../../assets";
import { Button, Radio, Textarea, UserPreview } from "../../components";
import { BlockReason } from "../../constants";
import { IReport } from "../../models";
import {
  blockCandidate,
  deleteCandidate,
} from "../../services/candidate.service";
import { blockEmployer, deleteEmployer } from "../../services/employer.service";
import { skipReport } from "../../services/report.service";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  item: IReport;
  onGetReports: (page: number, limit: number, search: string) => void;
}

const Report: FC<Props> = ({
  item,
  isOpen,
  setIsOpen,
  onGetReports,
}): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<
    "Skip" | "Block" | "Delete"
  >("Skip");
  const [blockReason, setBlockReason] = useState<string>("Other");
  const [otherReason, setOtherReason] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  console.log("item", item);
  const onChangeOtherReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
  };

  const openProfile = (type: string, id: string) => {
    if (type === "Candidate") {
      navigate(`/candidates/${id}`);
    } else if (type === "Employer") {
      navigate(`/companies/${id}`);
    }
  };

  const onSkipReport = async () => {
    try {
      setIsLoading(true);
      const { data } = await skipReport(item.id);
      if (data?.success) {
        onGetReports(1, 20, "");

        setIsOpen(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onDeleteAccount = async () => {
    try {
      setIsLoading(true);

      if (item?.toType === "candidate") {
        const { data } = await deleteCandidate(item?.to?.candidate_id);
        if (data?.success) {
          onGetReports(1, 20, "");

          setIsOpen(false);
        }
      } else {
        const { data } = await deleteEmployer(item?.to?.employer_id);
        if (data?.success) {
          onGetReports(1, 20, "");
          setIsOpen(false);
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const onBlockAccount = async () => {
    try {
      setIsLoading(true);

      if (item?.toType === "candidate") {
        const { data } = await blockCandidate(
          item?.to?.candidate_id,
          blockReason === "Other" ? otherReason : blockReason
        );
        if (data?.success) {
          onGetReports(1, 20, "");

          setIsOpen(false);
        }
      } else {
        const { data } = await blockEmployer(
          item?.to?.employer_id,
          blockReason === "Other" ? otherReason : blockReason
        );
        if (data?.success) {
          onGetReports(1, 20, "");
          setIsOpen(false);
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <h5 className={styles.sectionTitle}>Report</h5>
        <h4 className={styles.type}>{item?.category}</h4>
        <CrossIcon
          onClick={() => setIsOpen(false)}
          className={styles.closeBtn}
        />
        <div className={styles.infoRow}>
          <div className={styles.reporterWrapper}>
            <p className={styles.title}>Reporter</p>
            <UserPreview
              onClick={() =>
                openProfile(
                  item?.fromType,
                  item?.from.employer_id || item?.from?.candidate_id
                )
              }
              image={item?.from?.image}
              verified={item?.from?.verificationStatus === "Approved"}
              title={
                item?.from?.company ||
                `${item?.from?.firstName} ${item?.from?.lastName}`
              }
            />
          </div>
          <div className={styles.reportedWrapper}>
            {item?.to && (
              <>
                <p className={styles.title}>Reported on</p>
                <UserPreview
                  onClick={() =>
                    openProfile(
                      item?.toType,
                      item?.to.employer_id || item?.to?.candidate_id
                    )
                  }
                  image={item?.to?.image}
                  verified={item?.to?.verificationStatus === "Approved"}
                  title={
                    item?.to?.company ||
                    `${item?.to?.firstName} ${item?.to?.lastName}`
                  }
                />
              </>
            )}
          </div>
          <div className={styles.dateWrapper}>
            <CalendarIcon />
            <p>{format(new Date(item?.createdAt), "LLL d, yyyy")}</p>
          </div>
        </div>
        <h5 className={styles.sectionTitle}>Description</h5>
        <p className={styles.description}>{item?.description}</p>
        {item?.image && <h5 className={styles.sectionTitle}>Attachment</h5>}
        {item?.image && (
          <img
            src={item?.image}
            alt={"attachment"}
            className={styles.attachment}
          />
        )}
        <div className={styles.optionsContainer}>
          <div className={styles.radioOption}>
            <Radio
              isCheked={selectedOption === "Skip"}
              setIsCheked={() => setSelectedOption("Skip")}
            />
            <p className={styles.radioTitle}>Skip this report</p>
          </div>
          {item?.to && (
            <div className={styles.radioOption}>
              <Radio
                isCheked={selectedOption === "Block"}
                setIsCheked={() => setSelectedOption("Block")}
              />
              <p className={styles.radioTitle}>
                Block{" "}
                {item?.to?.company ||
                  `${item?.to?.firstName} ${item?.to?.lastName}`}
              </p>
            </div>
          )}
          {selectedOption === "Block" && (
            <div className={styles.blockReasonWrapper}>
              <p className={styles.title}>Choose a reason:</p>
              <div className={styles.reasonList}>
                {BlockReason.map((item, index) => {
                  return (
                    <div key={index} className={styles.radioOption}>
                      <Radio
                        isCheked={blockReason === item}
                        setIsCheked={() => setBlockReason(item)}
                      />
                      <p className={styles.radioTitle}>{item}</p>
                    </div>
                  );
                })}
                {blockReason === "Other" && (
                  <Textarea
                    placeholder="Message"
                    value={otherReason}
                    onChange={onChangeOtherReason}
                    maxLength={240}
                  />
                )}
              </div>
            </div>
          )}
          {item?.to && (
            <div className={styles.radioOption}>
              <Radio
                isCheked={selectedOption === "Delete"}
                setIsCheked={() => setSelectedOption("Delete")}
              />
              <p className={styles.radioTitle}>
                Delete{" "}
                {item?.to?.company ||
                  `${item?.to?.firstName} ${item?.to?.lastName}`}{" "}
                account
              </p>
            </div>
          )}
        </div>

        <Button
          title={"Send"}
          size="small"
          styleType={
            selectedOption === "Block" &&
            blockReason === "Other" &&
            !otherReason
              ? "disabled"
              : "filled"
          }
          loading={isLoading}
          disabled={
            selectedOption === "Block" &&
            blockReason === "Other" &&
            !otherReason
          }
          style={{ marginLeft: "auto", width: "160px" }}
          onClick={
            selectedOption === "Skip"
              ? onSkipReport
              : selectedOption === "Delete"
              ? onDeleteAccount
              : onBlockAccount
          }
        />
      </div>
    </Modal>
  );
};

export default Report;
