import { ChangeEvent, FC, useState, useEffect } from "react";
import { PulseLoader } from "react-spinners";
import { ArrowSmallDownIcon } from "../../../../assets";
import { CheckBox, PageItem, SearchInput } from "../../../../components";
import JobPostingCompany from "../../../../components/JobPostingCompany";
import { IJob } from "../../../../models";
import { getEmployerJobs } from "../../../../services/employer.service";
import { getJobs } from "../../../../services/job.service";
import styles from "./styles.module.scss";

interface Props {
  employerId: string;
}
const limit = 20;
const JobPosting: FC<Props> = ({ employerId }): JSX.Element => {
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobs, setJobs] = useState<IJob[]>([]);
  const [sort, setSort] = useState<number>(-1);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetJobs = async (
    page: number = 1,
    limit: number,
    search: string = ""
  ) => {
    try {
      setIsLoading(true);
      const { data } = await getEmployerJobs(employerId, page, limit, search);
      if (data?.success) {
        setPagesCount(Math.ceil(data?.count / limit));
        setJobs(data?.jobs);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        onGetJobs(1, limit, search);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!search) {
      onGetJobs(1, limit, search);
    }
  }, [search]);

  return (
    <>
      <div className={styles.searchWrapper}>
        <SearchInput
          placeholder="Search"
          value={search}
          onChange={onChangeSearch}
        />
      </div>
      <div className={styles.employmentHistory}>
        <div className={styles.sectionHeader}>
          <CheckBox isChecked={false} setIsChecked={() => {}} />
          <div className={styles.companyBlock}>
            <p>Job posting title</p> <ArrowSmallDownIcon />
          </div>
          <div className={styles.timeBlock}>
            <p>Workplace type</p> <ArrowSmallDownIcon />
          </div>

          <div className={styles.timeBlock}>
            <p>Employment type</p> <ArrowSmallDownIcon />
          </div>
          <div className={styles.timeBlock}>
            <p>Posting date</p> <ArrowSmallDownIcon />
          </div>
        </div>
        <div className={styles.jobContainer}>
          {isLoading && (
            <div className={styles.loaderWrapper}>
              <PulseLoader color="#7D4AFB" />
            </div>
          )}
          {!isLoading && jobs?.length === 0 && (
            <div className={styles.emptyTitle}>No job postings yet</div>
          )}
          {!isLoading &&
            jobs.map((item, index) => (
              <JobPostingCompany
                item={item}
                key={index}
                onGetJobs={onGetJobs}
              />
            ))}
        </div>
        <div className={styles.paginationContainer}>
          {Array(pagesCount === 0 ? 0 : pagesCount)
            .fill("_")
            .map((item, index) => (
              <PageItem
                onPress={() => onGetJobs(index + 1, limit, search)}
                key={index}
                page={index + 1}
                isActive={index + 1 === page}
              />
            ))}
          {pagesCount > 1 && page !== pagesCount && (
            <div
              onClick={() => onGetJobs(page + 1, limit, search)}
              className={styles.nextBtn}
            >
              Next
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default JobPosting;
