import { FC, useState } from "react";
import CheckBox from "../Checkbox";
import Tag from "../Tag";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import {
  ArrowSmallDownIcon,
  CheckBigIcon,
  CheckIcon,
  CrossIcon,
  PlaySquareIcon,
} from "../../assets";
import styles from "./styles.module.scss";
import { Request } from "../../modals";
import { IRequest } from "../../models";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

interface Props {
  item: any;
}

const CandidateMessageItem: FC<Props> = ({ item }): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const navigation = useNavigate();

  return (
    <>
      <div
        onClick={() => navigation(`/messages/candidate/${item?.id}`)}
        className={styles.wrapper}
      >
        <CheckBox isChecked={false} setIsChecked={() => {}} />
        <div className={styles.avatarWrapper}>
          <img
            src={item?.image || PlaceholderAvatar}
            alt="avatar"
            className={styles.avatar}
          />
          <p className={styles.name}>
            {item?.firstName} {item?.lastName}
          </p>
        </div>
        <div className={styles.tagsWrapper}>{item?.location}</div>

        <div className={styles.dateWrapper}>
          <p>{format(new Date(item?.createdAt), "LLL d, yyyy")}</p>
          <div className={styles.arrowBtn}>
            <ArrowSmallDownIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateMessageItem;
