import { FC, useState } from "react";
import CheckBox from "../Checkbox";
import Tag from "../Tag";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import {
  ArrowSmallDownIcon,
  CheckBigIcon,
  CheckIcon,
  CrossIcon,
  PlaySquareIcon,
} from "../../assets";
import styles from "./styles.module.scss";
import { BusinessVerification, Paid, Request } from "../../modals";
import { IRequest } from "../../models";
import { format } from "date-fns";
import { IBusinessVerificationItem } from "../../models/IBusinessVerificationItem";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { IReferral } from "../../models/IReferral";

interface Props {
  item: IReferral;
  onGetReferrals: () => void;
}

const ReferealItem: FC<Props> = ({ item, onGetReferrals }): JSX.Element => {
  const navigate = useNavigate();
  const [isShowPaidModal, setIsShowPaidModal] = useState(false);

  return (
    <>
      <Paid
        userName={`${item?.firstName} ${item?.lastName}`}
        candidateId={item?.id}
        amount={item?.requested}
        onGetCandidate={onGetReferrals}
        isOpen={isShowPaidModal}
        setIsOpen={setIsShowPaidModal}
      />
      <div
        onClick={() => navigate(`/referrals/${item?.id}`)}
        className={styles.wrapper}
      >
        <div className={styles.avatarWrapper}>
          <img
            src={item?.image || PlaceholderAvatar}
            alt="avatar"
            className={styles.avatar}
          />
          <p className={styles.name}>
            {item?.firstName} {item?.lastName}
          </p>
        </div>
        <div className={styles.headerBlock}>
          <p className={styles.headerBlockTitle}>{item?.location}</p>
        </div>
        <div className={styles.headerBlock}>
          <p className={styles.headerBlockTitle}>{item?.download}</p>
        </div>
        <div className={styles.headerBlock}>
          <p className={styles.headerBlockTitle}>{item?.signUp}</p>
        </div>
        <div className={styles.headerBlock}>
          <p className={styles.headerBlockTitle}>{item?.paid} SAR</p>
        </div>
        <div className={styles.headerBlock}>
          <p className={styles.headerBlockTitle}>{item?.available} SAR</p>
        </div>

        <div className={styles.dateWrapper}>
          <p>{item?.requested} SAR</p>
          <div className={styles.payButtonWrapper}>
            <Button
              size="small"
              title="Pay"
              disabled={!item?.requested}
              onClick={(e) => {
                e.stopPropagation();
                setIsShowPaidModal(true);
              }}
              styleType={!item?.requested ? "disabled" : "filled"}
            />
          </div>
          <div className={styles.arrowBtn}>
            <ArrowSmallDownIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferealItem;
