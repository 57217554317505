import { FC, useState } from "react";
import CheckBox from "../../../Checkbox";
import Tag from "../../../Tag";
import PlaceholderAvatar from "../../../../assets/images/avatar.png";
import {
  ArrowSmallDownIcon,
  CheckBigIcon,
  CheckIcon,
  CrossIcon,
  PlaySquareIcon,
} from "../../../../assets";
import styles from "./styles.module.scss";
import { format } from "date-fns";
import Button from "../../../Button";
import { useNavigate } from "react-router-dom";

interface Props {
  item: {
    amount: number;
    createdAt: string;
    updatedAt: string;
  };
}

const TransactionItem: FC<Props> = ({ item }): JSX.Element => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.headerBlock}>
          <p className={styles.headerBlockTitle}>{item?.amount} SAR</p>
        </div>

        <div className={styles.dateWrapper}>
          <p>{format(new Date(item?.createdAt), "MMM dd, yyyy, HH:mm")}</p>
          <div className={styles.payButtonWrapper}>Paid</div>
        </div>
      </div>
    </>
  );
};

export default TransactionItem;
