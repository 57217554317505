import { FC, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { PageItem } from "../../../../components";
import VideoItem from "../../../../components/VideoItem";
import { IEmployer, IVideo } from "../../../../models";
import { getEmployerVideos } from "../../../../services/employer.service";
import styles from "./styles.module.scss";

interface Props {
  employer: IEmployer | undefined;
}
const limit = 20;
const Video: FC<Props> = ({ employer }): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [videos, setVideo] = useState<IVideo[]>([]);

  const onGetVideos = async (page = 1, limit = 20) => {
    try {
      setIsLoading(true);
      const { data } = await getEmployerVideos(employer?.id || "", page, limit);
      if (data?.success) {
        setPagesCount(Math.ceil(data?.count / limit));
        setVideo(data?.videos);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetVideos(1, limit);
  }, []);

  return (
    <>
      {isLoading && (
        <div className={styles.loaderWrappaer}>
          <PulseLoader color="#7D4AFB" />
        </div>
      )}
      {!isLoading && (
        <div className={styles.videosContainer}>
          {videos.map((item, index) => (
            <VideoItem user={employer} item={item} key={index} />
          ))}
        </div>
      )}
      {!isLoading && videos?.length === 0 && (
        <div className={styles.emptyTitle}>No videos added yet</div>
      )}
      <div className={styles.paginationContainer}>
        {Array(pagesCount === 0 ? 0 : pagesCount)
          .fill("_")
          .map((item, index) => (
            <PageItem
              onPress={() => onGetVideos(index + 1, limit)}
              key={index}
              page={index + 1}
              isActive={index + 1 === page}
            />
          ))}
        {pagesCount > 1 && page !== pagesCount && (
          <div
            onClick={() => onGetVideos(page + 1, limit)}
            className={styles.nextBtn}
          >
            Next
          </div>
        )}
      </div>
    </>
  );
};

export default Video;
