import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

export const getCandidates = (
  page: number,
  limit: number,
  search: string,
  sort: number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      page,
      limit,
      sort,
    };
    if (search) {
      params.value = search;
    }

    axiosInstance
      .get("candidate/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getCandidateById = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`candidate/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getCandidateVideos = (
  employer_id: string,
  page: number,
  limit: number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      page,
      limit,
    };
    axiosInstance
      .get(`video/candidate/${employer_id}`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteCandidate = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`candidate/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const blockCandidate = (
  candidate_id: string,
  reason: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      candidate_id,
      reason,
    };
    axiosInstance
      .post("candidate/block", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.repsonse?.data));
  });
};

export const searchCandidateChats = (
  page: number,
  limit: number,
  sort: -1 | 1,
  search: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      page,
      limit,
      sort,
    };

    if (search) {
      params.value = search;
    }
    axiosInstance
      .get("chat/candidate/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getCandidateChats = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`chat/candidate/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
