import clsx from "clsx";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { ArrowSmallDownIcon } from "../../../assets";
import {
  Button,
  CheckBox,
  PageItem,
  RequestItem,
  SearchInput,
} from "../../../components";
import { IRequest } from "../../../models";
import { getRequests } from "../../../services/request.service";
import styles from "./styles.module.scss";
import BusinessVerificationItem from "../../../components/BusinessVerificationItem";
import { getBusinessVerificationRequests } from "../../../services/verification.service";
import { useDebounce } from "../../../hooks";
import PaginationButtons from "../../../components/PaginationButtons";
import { IBusinessVerificationItem } from "../../../models/IBusinessVerificationItem";

const limit = 20;

const BusinessVerification: FC = (): JSX.Element => {
  const [search, setSearch] = useState<string>("");
  const debounceSearch = useDebounce(search, 300);

  const page = useRef<number>(1);

  const [pagesCount, setPagesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<IBusinessVerificationItem[]>([]);
  const [sort, setSort] = useState<1 | -1>(1);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    page.current = 1;
    setSearch(e.target.value);
  };

  const onGetVerificationRequests = async () => {
    try {
      setIsLoading(true);
      const { data } = await getBusinessVerificationRequests(
        debounceSearch,
        limit,
        page.current,
        sort
      );
      if (data?.success) {
        setPagesCount(Math.ceil(data?.count / limit));
        setRequest(data?.employers);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetVerificationRequests();
  }, [debounceSearch, sort]);

  return (
    <>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>Verification</h4>
        </header>
        <section className={styles.contentContainer}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Search"
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div className={styles.sectionHeader}>
            <CheckBox isChecked={false} setIsChecked={() => {}} />
            <div className={styles.reporterBlock}>
              <p>Business</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.tagsBlock}>
              <p>Phone</p> <ArrowSmallDownIcon />
            </div>

            <div
              onClick={() => setSort((prev) => (prev === 1 ? -1 : 1))}
              className={clsx(styles.dateBlock, {
                [styles.dateBlockActive]: sort === 1,
              })}
            >
              <p>Registration date</p> <ArrowSmallDownIcon />
            </div>
          </div>
          <div className={styles.reportsContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#7D4AFB"} />
              </div>
            )}
            {!isLoading &&
              request.map((item, index) => {
                return (
                  <BusinessVerificationItem
                    onGetVerificationRequests={() => {
                      page.current = 1;
                      onGetVerificationRequests();
                    }}
                    item={item}
                    key={index}
                  />
                );
              })}
            {!isLoading && request?.length === 0 && (
              <div className={styles.emptyTitle}>There are no new requests</div>
            )}
          </div>
          <PaginationButtons
            pagesCount={pagesCount}
            page={page.current}
            onPress={(index) => {
              page.current = index;
              onGetVerificationRequests();
            }}
          />
        </section>
      </div>
    </>
  );
};

export default BusinessVerification;
