import { ChangeEvent, FC, useState } from "react";
import Modal from "react-modal";
import { CalendarIcon, ClockIcon, CrossIcon, SuitCaseIcon } from "../../assets";
import {
  Button,
  IconTag,
  Radio,
  Tag,
  Textarea,
  UserPreview,
} from "../../components";
import { BlockReason, VideoScores } from "../../constants";
import { IRequest } from "../../models";
import styles from "./styles.module.scss";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import { approveRequest, rejectRequest } from "../../services/request.service";
import { useNavigate } from "react-router-dom";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  item: IRequest;
  type: "Candidate" | "Employer";
  onGetRequests: (page: number, limit: number, search: string) => void;
}

const Request: FC<Props> = ({
  isOpen,
  setIsOpen,
  item,
  type,
  onGetRequests,
}): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<"Approve" | "Reject">(
    "Approve"
  );
  const [blockReason, setBlockReason] = useState<string>("Other");
  const [videoScore, setVideoScore] = useState<number>(1);
  const [otherReason, setOtherReason] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChangeOtherReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
  };

  const navigate = useNavigate();

  const onApproveVideo = async () => {
    try {
      setIsLoading(true);
      const { data } = await approveRequest(
        item?.candidate_id || item?.employer_id,
        type,
        videoScore
      );
      if (data?.success) {
        onGetRequests(1, 20, "");
        setIsOpen(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onRejectVideo = async () => {
    try {
      setIsLoading(true);
      const { data } = await rejectRequest(
        item?.candidate_id || item?.employer_id,
        type,
        blockReason === "Other" ? otherReason : blockReason
      );
      if (data?.success) {
        onGetRequests(1, 20, "");
        setIsOpen(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const openProfile = (type: string, id: string) => {
    if (type === "Candidate") {
      navigate(`/candidates/${id}`);
    } else if (type === "Employer") {
      navigate(`/companies/${id}`);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <UserPreview
          onClick={() =>
            openProfile(type, item?.candidate_id || item?.employer_id)
          }
          verified={item?.employer?.verificationStatus === "Approved"}
          image={
            item.candidate?.image ||
            item?.employer?.image ||
            (type == "Candidate" ? PlaceholderAvatar : PlaceholderCompanyAvatar)
          }
          title={
            item?.candidate
              ? `${item?.candidate?.firstName} ${item?.candidate?.lastName}`
              : item.employer?.company
          }
        />
        <CrossIcon
          onClick={() => setIsOpen(false)}
          className={styles.closeBtn}
        />

        <div className={styles.contentContainer}>
          <div className={styles.videoWrapper}>
            <video src={item.url} controls className={styles.video}></video>
          </div>
          <div className={styles.infoWrapper}>
            {item.workplace && item.employment && (
              <div className={styles.jobInfo}>
                <IconTag icon={<SuitCaseIcon />} title={item.workplace} />
                <IconTag icon={<ClockIcon />} title={item.employment} />
              </div>
            )}
            <div className={styles.sectionTitle}>Tags</div>
            <div className={styles.tagsContainer}>
              {item?.tags?.map((item, index) => (
                <Tag title={item} key={index} />
              ))}
            </div>

            <div className={styles.optionsContainer}>
              <div className={styles.radioOption}>
                <Radio
                  isCheked={selectedOption === "Approve"}
                  setIsCheked={() => setSelectedOption("Approve")}
                />
                <p className={styles.radioTitle}>Approve the video</p>
              </div>
              {selectedOption === "Approve" && (
                <div className={styles.blockReasonWrapper}>
                  <p className={styles.title}>Give a score to this video:</p>
                  <div className={styles.videoScoresList}>
                    {VideoScores.map((item, index) => {
                      return (
                        <div key={index} className={styles.radioOption}>
                          <Radio
                            isCheked={videoScore === index + 1}
                            setIsCheked={() => setVideoScore(index + 1)}
                          />
                          <p className={styles.radioTitle}>{item}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className={styles.radioOption}>
                <Radio
                  isCheked={selectedOption === "Reject"}
                  setIsCheked={() => setSelectedOption("Reject")}
                />
                <p className={styles.radioTitle}>Reject the video</p>
              </div>
              {selectedOption === "Reject" && (
                <div className={styles.blockReasonWrapper}>
                  <p className={styles.title}>Reason of rejection:</p>
                  <div className={styles.reasonList}>
                    {BlockReason.map((item, index) => {
                      return (
                        <div key={index} className={styles.radioOption}>
                          <Radio
                            isCheked={blockReason === item}
                            setIsCheked={() => setBlockReason(item)}
                          />
                          <p className={styles.radioTitle}>{item}</p>
                        </div>
                      );
                    })}
                    {blockReason === "Other" && (
                      <Textarea
                        placeholder="Message"
                        value={otherReason}
                        onChange={onChangeOtherReason}
                        maxLength={240}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            <Button
              onClick={
                selectedOption === "Approve" ? onApproveVideo : onRejectVideo
              }
              disabled={
                selectedOption === "Reject" &&
                blockReason === "Other" &&
                !otherReason
              }
              loading={isLoading}
              title={"Save"}
              size={"small"}
              width={"100%"}
              styleType={
                selectedOption === "Reject" &&
                blockReason === "Other" &&
                !otherReason
                  ? "disabled"
                  : "filled"
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Request;
