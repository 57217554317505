import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

export const getMessagesInChat = (
  id: string,
  limit: number,
  last?: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      limit,
    };

    if (last) {
      params.last = last;
    }

    axiosInstance
      .get(`chat/messages/${id}`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
