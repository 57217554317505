import { FC, useState } from "react";
import CheckBox from "../Checkbox";
import styles from "./styles.module.scss";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import ReportType from "../ReportType";
import { ArrowSmallDownIcon, ClockIcon, SuitCaseIcon } from "../../assets";
import { JobPosting } from "../../modals";
import UserPreview from "../UserPreview";
import { IJob } from "../../models";
import format from "date-fns/format";
import IconTag from "../IconTag";

interface Props {
  item: IJob;
  onGetJobs: (page: number, limit: number, search: string) => void;
}

const JobPostingCompany: FC<Props> = ({ item, onGetJobs }): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  return (
    <>
      <JobPosting
        item={item}
        onGetJobs={onGetJobs}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
      />
      <div onClick={() => setIsOpenModal(true)} className={styles.wrapper}>
        <CheckBox isChecked={false} setIsChecked={() => {}} />
        <div className={styles.avatarWrapper}>
          <p className={styles.name}>{item.title}</p>
        </div>

        <div className={styles.companyWrapper}>
          <IconTag icon={<SuitCaseIcon />} title={item.workplace} />
        </div>
        <div className={styles.companyWrapper}>
          <IconTag icon={<ClockIcon />} title={item.employment} />
        </div>
        <div className={styles.dateWrapper}>
          <p>{format(new Date(item.createdAt), "LLL d, yyyy")}</p>

          <div className={styles.arrowBtn}>
            <ArrowSmallDownIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobPostingCompany;
