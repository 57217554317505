import { FC } from "react";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  backgroundColor?: string;
}

const Tag: FC<Props> = ({
  title,
  backgroundColor = "#7D4AFB",
}): JSX.Element => {
  return (
    <div style={{ backgroundColor }} className={styles.wrapper}>
      {title}
    </div>
  );
};

export default Tag;
