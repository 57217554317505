import { FC, useState } from "react";
import CheckBox from "../Checkbox";
import Tag from "../Tag";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import {
  ArrowSmallDownIcon,
  CheckBigIcon,
  CheckIcon,
  CrossIcon,
  PlaySquareIcon,
} from "../../assets";
import styles from "./styles.module.scss";
import { Influencers, Request } from "../../modals";
import { IRequest } from "../../models";
import { format } from "date-fns";
import { IPropositions } from "../../models/IPropositions";
import UserPreview from "../UserPreview";

interface Props {
  item: IPropositions;
}

const InfluencerItem: FC<Props> = ({ item }): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  return (
    <>
      <Influencers
        item={item}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
      />
      <div onClick={() => setIsOpenModal(true)} className={styles.wrapper}>
        <div className={styles.avatarWrapper}>
          <img
            src={item?.candidate?.image || PlaceholderAvatar}
            alt="avatar"
            className={styles.avatar}
          />
          <p className={styles.name}>
            {item?.candidate?.firstName} {item?.candidate?.lastName}
          </p>
        </div>
        <div className={styles.tagsWrapper}>{item?.candidate?.location}</div>
        <div className={styles.tagsWrapper}>
          <UserPreview
            image={item?.employer?.image}
            title={item?.employer?.company}
            verified={item?.employer?.verificationStatus === "Approved"}
          />
        </div>

        <div className={styles.dateWrapper}>
          {format(new Date(item?.createdAt), "MMM dd, yyyy HH:mm")}
        </div>
        <div className={styles.statusWrapper}>
          <Tag
            title={item?.status}
            backgroundColor={
              item?.status === "Pending"
                ? "#FFCE73"
                : item?.status === "Approved"
                ? "#7FBA7A"
                : "#FF3C3C"
            }
          />
          <div
            onClick={() => setIsOpenModal(true)}
            className={styles.playVideoBtn}
          >
            <PlaySquareIcon />
            <span>Play video</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfluencerItem;
