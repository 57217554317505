import clsx from "clsx";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { ArrowSmallDownIcon, FilterIcon } from "../../../assets";
import {
  Button,
  CheckBox,
  CheckItem,
  PageItem,
  ReportItem,
  SearchInput,
} from "../../../components";
import styles from "./styles.module.scss";
import { motion } from "framer-motion";
import { useOutsideClick } from "../../../hooks";
import { reportCategories, reportTypes } from "../../../constants";
import { getReports } from "../../../services/report.service";
import { PulseLoader } from "react-spinners";
import { IReport } from "../../../models";
const limit = 20;
const Reports: FC = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<
    "Companies" | "Candidates" | "All"
  >("All");
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reports, setReports] = useState<IReport[]>([]);
  const [sort, setSort] = useState<number>(-1);
  const filterRef = useRef<HTMLDivElement>(null);
  const [activeTypes, setActiveTypes] = useState<string[]>([]);
  const [activeCategories, setActiveCategories] = useState<string[]>([]);

  useOutsideClick(filterRef, () => setIsOpenFilter(false));

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetReports = async (
    page: number = 1,
    limit: number,
    search: string = ""
  ) => {
    try {
      setIsLoading(true);

      const { data } = await getReports(
        page,
        limit,
        search,
        activeCategories,
        activeTypes,
        sort,
        activeTab
      );
      if (data?.success) {
        setPagesCount(Math.ceil(data?.count / limit));
        setReports(data?.reports);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        onGetReports(1, limit, search);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search, sort, activeTab, activeTypes, activeCategories]);

  useEffect(() => {
    if (!search) {
      onGetReports(1, limit, search);
    }
  }, [search, sort, activeTab, activeTypes, activeCategories]);

  return (
    <>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>Reports</h4>
        </header>
        <section className={styles.contentContainer}>
          <div className={styles.sortSwitchersWrapper}>
            <Button
              title={"All "}
              size={"x-small"}
              onClick={() => setActiveTab("All")}
              styleType={activeTab === "All" ? "filled" : "inactive"}
            />
            <Button
              title={"Companies"}
              size={"x-small"}
              onClick={() => setActiveTab("Companies")}
              styleType={activeTab === "Companies" ? "filled" : "inactive"}
            />
            <Button
              title={"Candidates"}
              size={"x-small"}
              onClick={() => setActiveTab("Candidates")}
              styleType={activeTab === "Candidates" ? "filled" : "inactive"}
            />
            <div className={styles.filterContainer} ref={filterRef}>
              <div
                onClick={() => setIsOpenFilter((prev) => !prev)}
                className={clsx(styles.filterBtn, {
                  [styles.activeFilterBtn]: isOpenFilter,
                })}
              >
                <FilterIcon />
              </div>
              {isOpenFilter && (
                <motion.div
                  initial={{ scale: 0.7, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                  className={styles.filterWrapper}
                >
                  <div className={styles.header}>
                    <p className={styles.title}>Filter by</p>
                    <p
                      onClick={() => {
                        setActiveTypes([]);
                        setActiveCategories([]);
                      }}
                      className={styles.clearFilterBtn}
                    >
                      Clear all filters
                    </p>
                  </div>
                  <p className={styles.sectionTitle}>Type</p>
                  <div className={styles.list}>
                    {reportTypes.map((item) => (
                      <CheckItem
                        isChecked={activeTypes?.includes(item)}
                        onSelect={() => {
                          setActiveTypes((prev) => {
                            const copy = [...prev];
                            const index = copy?.findIndex((el) => item === el);
                            if (index === -1) {
                              copy.push(item);
                            } else {
                              copy.splice(index, 1);
                            }
                            return copy;
                          });
                        }}
                        title={item}
                      />
                    ))}
                  </div>
                  <div className={styles.divider} />
                  <p className={styles.sectionTitle}>Category</p>
                  <div className={styles.list}>
                    {reportCategories.map((item) => (
                      <CheckItem
                        isChecked={activeCategories?.includes(item)}
                        onSelect={() => {
                          setActiveCategories((prev) => {
                            const copy = [...prev];
                            const index = copy?.findIndex((el) => item === el);
                            if (index === -1) {
                              copy.push(item);
                            } else {
                              copy.splice(index, 1);
                            }
                            return copy;
                          });
                        }}
                        title={item}
                      />
                    ))}
                  </div>
                  <div className={styles.btndivider} />
                  <Button
                    onClick={() => setIsOpenFilter(false)}
                    title={"Apply"}
                    size={"small"}
                    styleType={"filled"}
                  />
                </motion.div>
              )}
            </div>
          </div>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Search"
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div className={styles.sectionHeader}>
            <CheckBox isChecked={false} setIsChecked={() => {}} />
            <div className={styles.reporterBlock}>
              <p>Reporter</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.typeBlock}>
              <p>Type</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.categoryBlock}>
              <p>Category</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.reportedOnBlock}>
              <p>Reported On</p> <ArrowSmallDownIcon />
            </div>
            <div
              onClick={() => setSort((prev) => (prev === 1 ? -1 : 1))}
              className={clsx(styles.dateBlock, {
                [styles.dateBlockActive]: sort === 1,
              })}
            >
              <p>Report date</p> <ArrowSmallDownIcon />
            </div>
          </div>
          <div className={styles.reportsContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#7D4AFB"} />
              </div>
            )}
            {!isLoading &&
              reports.map((item, index) => {
                return (
                  <ReportItem
                    item={item}
                    key={item?.id}
                    onGetReports={onGetReports}
                  />
                );
              })}
            {!isLoading && reports?.length === 0 && (
              <div className={styles.emptyTitle}>There are no reports</div>
            )}
          </div>
          <div className={styles.paginationContainer}>
            {Array(pagesCount === 0 ? 0 : pagesCount)
              .fill("_")
              .map((item, index) => (
                <PageItem
                  onPress={() => onGetReports(index + 1, limit, search)}
                  key={index}
                  page={index + 1}
                  isActive={index + 1 === page}
                />
              ))}
            {pagesCount > 1 && page !== pagesCount && (
              <div
                onClick={() => onGetReports(page + 1, limit, search)}
                className={styles.nextBtn}
              >
                Next
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Reports;
