import { FC, useState } from "react";
import CheckBox from "../Checkbox";
import Tag from "../Tag";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import {
  ArrowSmallDownIcon,
  CheckBigIcon,
  CheckIcon,
  CrossIcon,
  PlaySquareIcon,
  VerifiedIcon,
} from "../../assets";
import styles from "./styles.module.scss";
import { Request } from "../../modals";
import { IRequest } from "../../models";
import { format } from "date-fns";

interface Props {
  item: IRequest;
  type: "Candidate" | "Employer";
  onGetRequests: (page: number, limit: number, search: string) => void;
}

const RequestItem: FC<Props> = ({ item, type, onGetRequests }): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  return (
    <>
      <Request
        onGetRequests={onGetRequests}
        type={type}
        item={item}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
      />
      <div onClick={() => setIsOpenModal(true)} className={styles.wrapper}>
        <CheckBox isChecked={false} setIsChecked={() => {}} />
        <div className={styles.avatarWrapper}>
          <img
            src={
              item?.candidate?.image ||
              item?.employer?.image ||
              (type === "Candidate"
                ? PlaceholderAvatar
                : PlaceholderCompanyAvatar)
            }
            alt="avatar"
            className={styles.avatar}
          />
          <p className={styles.name}>
            {item?.candidate
              ? `${item.candidate?.firstName} ${item?.candidate?.lastName}`
              : item?.employer?.company}
            {item?.employer?.verificationStatus === "Approved" && (
              <VerifiedIcon style={{ marginLeft: 5 }} />
            )}
          </p>
        </div>
        <div className={styles.tagsWrapper}>
          {item?.tags?.map((el, index) => {
            if (index > 2) {
              return null;
            }
            if (index > 1) {
              return <Tag key={index} title={`+ ${item?.tags?.length - 2}`} />;
            }

            return <Tag key={index} title={el} />;
          })}
        </div>

        <div className={styles.dateWrapper}>
          <p>{format(new Date(item.createdAt), "LLL d, yyyy")}</p>

          <div
            onClick={() => setIsOpenModal(true)}
            className={styles.playVideoBtn}
          >
            <PlaySquareIcon />
            <span>Play video</span>
          </div>

          <div className={styles.btnWrapper}>
            <div
              onClick={() => setIsOpenModal(true)}
              className={styles.approveBtn}
            >
              <CheckBigIcon />
            </div>

            <div
              onClick={() => setIsOpenModal(true)}
              className={styles.declineBtn}
            >
              <CrossIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestItem;
