import { FC, useState } from "react";
import CheckBox from "../Checkbox";
import styles from "./styles.module.scss";
import PlaceholderCompanyAvatar from "../../assets/images/companyAvatar.png";
import PlaceholderAvatar from "../../assets/images/avatar.png";

import ReportType from "../ReportType";
import { ArrowSmallDownIcon } from "../../assets";
import { Report } from "../../modals";
import { IReport } from "../../models";
import { format } from "date-fns";

interface Props {
  item: IReport;
  onGetReports: (page: number, limit: number, search: string) => void;
}

const ReportItem: FC<Props> = ({ item, onGetReports }): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  return (
    <>
      <Report
        onGetReports={onGetReports}
        item={item}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
      />
      <div onClick={() => setIsOpenModal(true)} className={styles.wrapper}>
        <CheckBox isChecked={false} setIsChecked={() => {}} />
        <div className={styles.avatarWrapper}>
          <img
            src={
              item?.from?.image
                ? item?.from?.image
                : item?.fromType === "candidate"
                ? PlaceholderAvatar
                : PlaceholderCompanyAvatar
            }
            alt="avatar"
            className={styles.avatar}
          />
          <p className={styles.name}>
            {item?.from?.company ||
              `${item?.from?.firstName} ${item?.from?.lastName}`}
          </p>
        </div>
        <div className={styles.typeWrapper}>
          <ReportType
            type={item?.type === "Problem report" ? "problem" : "user"}
          />
        </div>
        <div className={styles.categoryWrapper}>
          <p>{item?.category}</p>
        </div>
        <div className={styles.reportedWrapper}>
          {item?.to && (
            <>
              {" "}
              <img
                src={
                  item?.to?.image
                    ? item?.to?.image
                    : item?.toType === "candidate"
                    ? PlaceholderAvatar
                    : PlaceholderCompanyAvatar
                }
                alt="avatar"
                className={styles.reportedAvatar}
              />
              <p>
                {item?.to?.company ||
                  `${item?.to?.firstName} ${item?.to?.lastName}`}
              </p>
            </>
          )}
        </div>
        <div className={styles.dateWrapper}>
          <p>{format(new Date(item?.createdAt), "LLL d, yyyy")}</p>

          <div className={styles.arrowBtn}>
            <ArrowSmallDownIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportItem;
