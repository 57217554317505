import clsx from "clsx";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { ArrowSmallDownIcon } from "../../../assets";
import {
  ApplicationItem,
  Button,
  CandidateItem,
  CheckBox,
  JobPostingItem,
  PageItem,
  RequestItem,
  SearchInput,
} from "../../../components";
import { IApplicant } from "../../../models";
import { getApplicants } from "../../../services/job.service";
import styles from "./styles.module.scss";
import PaginationButtons from "../../../components/PaginationButtons";
const limit = 20;
const Applications: FC = (): JSX.Element => {
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [applicants, setApplicants] = useState<IApplicant[]>([]);
  const [sort, setSort] = useState<number>(-1);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetApplicants = async (
    page: number = 1,
    limit: number,
    search: string = ""
  ) => {
    try {
      setIsLoading(true);

      const { data } = await getApplicants(page, limit, search, sort);
      if (data?.success) {
        setPagesCount(Math.ceil(data?.count / limit));
        setApplicants(data?.applicants);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        onGetApplicants(1, limit, search);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search, sort]);

  useEffect(() => {
    if (!search) {
      onGetApplicants(1, limit, search);
    }
  }, [search, sort]);

  return (
    <>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>Applications</h4>
        </header>
        <section className={styles.contentContainer}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Search"
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div className={styles.sectionHeader}>
            <CheckBox isChecked={false} setIsChecked={() => {}} />
            <div className={styles.jobBlock}>
              <p>Candidate</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.companyBlock}>
              <p>Company</p> <ArrowSmallDownIcon />
            </div>
            <div className={styles.workBlock}>
              <p>Work title</p> <ArrowSmallDownIcon />
            </div>

            <div
              onClick={() => setSort((prev) => (prev === 1 ? -1 : 1))}
              className={clsx(styles.dateBlock, {
                [styles.dateBlockActive]: sort === 1,
              })}
            >
              <p>Registration date</p> <ArrowSmallDownIcon />
            </div>
          </div>
          <div className={styles.reportsContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#7D4AFB"} />
              </div>
            )}
            {!isLoading &&
              applicants.map((item, index) => {
                return <ApplicationItem item={item} key={item.id} />;
              })}
            {!isLoading && applicants?.length === 0 && (
              <div className={styles.emptyTitle}>There are no applications</div>
            )}
          </div>
          <PaginationButtons
            pagesCount={pagesCount}
            page={page}
            onPress={(index) => onGetApplicants(index, limit, search)}
          />
        </section>
      </div>
    </>
  );
};

export default Applications;
