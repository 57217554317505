import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import MessageItem from "../MessageItem";
import { getMessagesInChat } from "../../services/chat.service";
import { IMessage } from "../../models/IMessage";
import { PulseLoader } from "react-spinners";
import { el } from "date-fns/locale";
import { off } from "process";

interface IProps {
  id: string;
  userId: string | undefined;
  userImage: string | null | undefined;
  interlocutorImage: string | null;
  interlocutorName: string;
  userName: string;
  type: "candidate" | "employer";
  offer: any;
}

const limit = 50;
const Chat: FC<IProps> = ({
  id,
  userId,
  userImage,
  interlocutorImage,
  type,
  userName,
  interlocutorName,
  offer,
}) => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [isHasMore, setIsHasMore] = useState(true);
  const onGetMessages = async (isLoadingPagination: boolean = false) => {
    try {
      if (isLoadingPagination) {
        setIsLoadingPagination(true);
      } else {
        setIsLoading(true);
      }

      const { data } = await getMessagesInChat(
        id,
        limit,
        isLoadingPagination ? messages[messages?.length - 1]?.id : undefined
      );

      if (data?.success) {
        if (data?.messages?.length < limit) {
          setIsHasMore(false);
        }
        if (isLoadingPagination) {
          setMessages((prev) =>
            offer
              ? [
                  {
                    type: "Offer",
                    content: { ...offer },
                    id: offer?.id,
                    uuid: "Offer1",
                  },
                  ...prev,
                  ...data?.messages,
                ]
              : [...prev, ...data?.messages]
          );
        } else {
          setMessages(
            offer
              ? [
                  {
                    type: "Offer",
                    content: { ...offer },
                    id: offer?.id,
                    uuid: "Offer1",
                  },
                  ...data?.messages,
                ]
              : [...data?.messages]
          );
        }
      }
    } catch (error) {
    } finally {
      setIsLoadingPagination(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setIsHasMore(true);

      onGetMessages();
    }
  }, [id]);

  const handleScroll = (e: any) => {
    const isTop =
      e.target.scrollTop <=
      70 + e.target.clientHeight - e.target.scrollHeight + 1;
    if (isTop && !isLoadingPagination && !isLoading && isHasMore) {
      onGetMessages(true);
    }
  };

  return (
    <div onScroll={handleScroll} className={styles.wrapper}>
      {isLoading && (
        <div className={styles.pulseLoader}>
          <PulseLoader color="#7D4AFB" />
        </div>
      )}
      {!isLoading &&
        messages.map((item, index) => (
          <MessageItem
            key={item?.id}
            item={item}
            direction={item?.author_id === userId ? "right" : "left"}
            userImage={userImage}
            interlocutorImage={interlocutorImage}
            type={type}
            userName={userName}
            isNextHaveSameAuthor={
              messages[index - 1]?.author_id === item?.author_id
            }
            interlocutorName={interlocutorName}
          />
        ))}
      {isLoadingPagination && (
        <div className={styles.loaderPagination}>
          <PulseLoader color="#7D4AFB" />
        </div>
      )}
    </div>
  );
};

export default Chat;
