import { FC } from "react";
import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import { VerifiedIcon } from "../../assets";

interface Props {
  image?: string | null;
  title?: string;
  onClick?: () => void;
  verified: boolean;
}

const UserPreview: FC<Props> = ({
  image,
  title,
  onClick = () => {},
  verified,
}): JSX.Element => {
  return (
    <div onClick={onClick} className={styles.wrapper}>
      <img
        src={image ? image : PlaceholderAvatar}
        alt={"avatar"}
        className={styles.avatar}
      />
      <p className={styles.name}>
        {title}
        {verified && <VerifiedIcon style={{ marginLeft: 5 }} />}
      </p>
    </div>
  );
};

export default UserPreview;
