import { FC, useCallback, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Logo from "../../assets/icons/logo.svg";
import Button from "../Button";
import {
  CandidatesIcon,
  CompaniesIcon,
  InfluencersIcon,
  LogoutIcon,
  MessageIcon,
  NewReportIcon,
  ReferralsIcon,
  ReportIcon,
  StaffIcon,
  StatisticsIcon,
  VerificationIcon,
} from "../../assets";
import { NavLink, useNavigate } from "react-router-dom";
import SidebarDropdown from "../SidebarDropdown";
import clsx from "clsx";
import {
  getUser,
  removeAccessToken,
  removeRefreshToken,
} from "../../utils/tokenStorage";
import { logout } from "../../services/auth.service";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import { motion } from "framer-motion";
import { useOutsideClick } from "../../hooks";

const Sidebar: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const me = getUser();
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => setIsOpenMenu(false));

  const onLogOut = () => {
    logout();
    removeAccessToken();
    removeRefreshToken();
    navigate("/signin");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonsContainer}>
        <img src={Logo} alt={"logo"} className={styles.logo} />

        <NavLink to={"/statistics"} className={styles.linkBtn}>
          <StatisticsIcon />
          <p>Statistics</p>
        </NavLink>
        <SidebarDropdown icon={<CompaniesIcon />} title={"Companies"}>
          <NavLink
            to={"/companies"}
            className={clsx(styles.linkBtn, styles.dropdownLink)}
          >
            <div style={{ width: "24px" }} />
            <p>All Companies</p>
          </NavLink>
          <NavLink
            to={"/job-posting"}
            className={clsx(styles.linkBtn, styles.dropdownLink)}
          >
            <div style={{ width: "24px" }} />
            <p>Job Postings</p>
          </NavLink>
        </SidebarDropdown>
        <SidebarDropdown icon={<CandidatesIcon />} title={"Candidates"}>
          <NavLink
            to={"/candidates"}
            className={clsx(styles.linkBtn, styles.dropdownLink)}
          >
            <div style={{ width: "24px" }} />
            <p>All Candidates</p>
          </NavLink>
          <NavLink
            to={"/applications"}
            className={clsx(styles.linkBtn, styles.dropdownLink)}
          >
            <div style={{ width: "24px" }} />
            <p>Applications</p>
          </NavLink>
        </SidebarDropdown>
        <NavLink to={"/influencers"} className={styles.linkBtn}>
          <InfluencersIcon />
          <p>Influencers</p>
        </NavLink>
        <NavLink to={"/messages"} className={styles.linkStrokeBtn}>
          <MessageIcon />
          <p>Messages</p>
        </NavLink>
        <NavLink to={"/new-requests"} className={styles.linkBtn}>
          <NewReportIcon />
          <p>New Requests</p>
        </NavLink>

        <NavLink to={"/business-verification"} className={styles.linkBtn}>
          <VerificationIcon />
          <p>Verification</p>
        </NavLink>
        <NavLink to={"/referrals"} className={styles.linkBtn}>
          <ReferralsIcon />
          <p>Referrals</p>
        </NavLink>
        <NavLink to={"/reports"} className={styles.linkBtn}>
          <ReportIcon />
          <p>Reports</p>
        </NavLink>
        {me?.type === "Admin" && (
          <NavLink to={"/staff-management"} className={styles.linkBtn}>
            <StaffIcon />
            <p>Staff Management</p>
          </NavLink>
        )}
      </div>
      <div
        ref={ref}
        onClick={() => setIsOpenMenu((prev) => !prev)}
        className={styles.profileContainer}
      >
        <img
          src={me?.image ? me?.image : PlaceholderAvatar}
          alt={"avatar"}
          className={styles.avatar}
        />
        <div className={styles.infoContainer}>
          <p className={styles.name}>{me?.fullName}</p>
          <p className={styles.role}>{me?.type}</p>
        </div>
        {isOpenMenu && (
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className={styles.menu}
          >
            <div onClick={onLogOut} className={styles.logoutBtn}>
              <LogoutIcon />
              Logout
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};
export default Sidebar;
