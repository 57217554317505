import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowLineIcon,
  ArrowSmallDownIcon,
  InstagramIcon,
  LinkedInIcon,
} from "../../../assets";
import {
  Button,
  CvItem,
  EducationItem,
  EmploymentItem,
} from "../../../components";
import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../../assets/images/avatar.png";
import VideoItem from "../../../components/VideoItem";
import { ICandidate } from "../../../models";
import {
  deleteCandidate,
  getCandidateById,
} from "../../../services/candidate.service";
import { PulseLoader } from "react-spinners";
import format from "date-fns/format";
import Video from "./Video";
import { openLink } from "../../../utils";
import { BlockUser, Delete } from "../../../modals";

interface Props {}

const CandidateProfile: FC<Props> = (): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<"About" | "Videos">("About");
  const [candidate, setCandidate] = useState<ICandidate>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowBlockModal, setIsShowBlockModal] = useState<boolean>(false);

  const params = useParams();
  const navigate = useNavigate();

  const onGetCandidate = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getCandidateById(id);
      if (data?.success) {
        setCandidate(data?.candidate);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onDeleteCandidate = async () => {
    try {
      setIsLoadingDelete(true);
      const { data } = await deleteCandidate(params?.id || "");
      if (data?.success) {
        navigate(-1);
      }
      setIsLoadingDelete(false);
    } catch (error) {
      setIsLoadingDelete(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      onGetCandidate(params.id);
    }
  }, [params]);

  return (
    <>
      <BlockUser
        type={"candidate"}
        userId={candidate?.id || ""}
        isOpen={isShowBlockModal}
        setIsOpen={setIsShowBlockModal}
      />
      <Delete
        onDelete={onDeleteCandidate}
        isLoading={isLoadingDelete}
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
      />
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div onClick={() => navigate(-1)} className={styles.backBtn}>
            <ArrowLineIcon />
            <span>Candidate Profile</span>
          </div>
          <div className={styles.btnWrapper}>
            <Button
              title={"Block"}
              size="small"
              styleType="outlined"
              style={{ border: "none", width: "87px", background: "#E4E4E4" }}
              onClick={() => setIsShowBlockModal(true)}
            />
            <Button
              onClick={() => setIsShowDeleteModal(true)}
              title={"Delete"}
              size="small"
              styleType="filled"
              style={{ border: "none", width: "87px", background: "#FF3C3C" }}
            />
          </div>
        </div>
        <div className={styles.contentContainer}>
          {isLoading ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          ) : (
            <>
              <div className={styles.header}>
                <div className={styles.info}>
                  <img
                    src={
                      candidate?.image ? candidate?.image : PlaceholderAvatar
                    }
                    alt="avatar"
                    className={styles.avatar}
                  />
                  <div className={styles.nameWrapper}>
                    <p className={styles.name}>
                      {candidate?.firstName} {candidate?.lastName}
                    </p>
                    <p className={styles.location}>{candidate?.location}</p>
                    <p className={styles.location}>{candidate?.email}</p>
                  </div>
                </div>
                <div className={styles.socialbtnWrapper}>
                  {candidate?.linkedin && (
                    <LinkedInIcon
                      className={styles.socialBtn}
                      onClick={() => {
                        openLink(candidate?.linkedin || "");
                      }}
                    />
                  )}
                  {candidate?.instagram && (
                    <InstagramIcon
                      className={styles.socialBtn}
                      onClick={() => {
                        openLink(
                          `https://www.instagram.com/${candidate?.instagram?.slice(
                            1
                          )}`
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={styles.tabWrapper}>
                <Button
                  onClick={() => setCurrentTab("About")}
                  size="x-small"
                  title="About"
                  styleType={currentTab === "About" ? "filled" : "inactive"}
                />
                <Button
                  onClick={() => setCurrentTab("Videos")}
                  size="x-small"
                  title="Video"
                  styleType={currentTab === "Videos" ? "filled" : "inactive"}
                />
              </div>
              {currentTab === "About" ? (
                <>
                  <div className={styles.profileInfo}>
                    {candidate && candidate?.educationHistory?.length > 0 && (
                      <div className={styles.profileBlock}>
                        <p className={styles.profleTitle}>Education</p>
                        {candidate?.educationHistory?.map((item, index) => (
                          <EducationItem key={item._id} item={item} />
                        ))}
                      </div>
                    )}
                    <div className={styles.profileBlock} style={{ flex: 0.7 }}>
                      <p className={styles.profleTitle}>
                        Available Starting Date
                      </p>
                      <p className={styles.date}>
                        {candidate?.availableDate
                          ? format(
                              new Date(candidate?.availableDate),
                              "MMMM d, yyyy"
                            )
                          : "Immediately"}
                      </p>
                    </div>
                    {candidate && candidate?.CVs?.length > 0 && (
                      <div
                        className={styles.profileBlock}
                        style={{ flex: 0.8 }}
                      >
                        <p className={styles.profleTitle}>My CVs</p>
                        {candidate?.CVs?.map((item, index) => (
                          <CvItem item={item} key={index} />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={styles.employmentHistory}>
                    <p className={styles.title}>Employment History</p>
                    <div className={styles.sectionHeader}>
                      <div className={styles.companyBlock}>
                        <p>Company name</p> <ArrowSmallDownIcon />
                      </div>
                      <div className={styles.workBlock}>
                        <p>Work title</p> <ArrowSmallDownIcon />
                      </div>

                      <div className={styles.timeBlock}>
                        <p>Time worked</p> <ArrowSmallDownIcon />
                      </div>
                    </div>
                    {candidate &&
                      candidate?.employmentHistory?.length === 0 && (
                        <div className={styles.emptyTitle}>
                          No employment history added yet
                        </div>
                      )}
                    <div>
                      {candidate?.employmentHistory?.map((item, index) => (
                        <EmploymentItem item={item} key={item.id} />
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <Video candidate={candidate} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CandidateProfile;
