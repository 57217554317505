import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { ArrowSmallDownIcon } from "../../assets";
import clsx from "clsx";
import TransactionItem from "./components/TransactionItem";
import { PulseLoader } from "react-spinners";
import { getReferralTransaction } from "../../services/referral.service";

interface IProps {
  id: string | undefined;
}

interface ITransaction {
  amount: number;
  createdAt: string;
  updatedAt: string;
}

const TransactionsList: FC<IProps> = ({ id }) => {
  const [sort, setSort] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);

  const onGetTransaction = async () => {
    try {
      setIsLoading(true);
      const { data } = await getReferralTransaction(id as string);
      if (data?.success) {
        setTransactions(data?.transactions);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTransactions((prev) => {
      //@ts-ignore
      const copy = [...prev];
      return copy.sort((a: any, b: any) => {
        if (sort === -1) {
          //@ts-ignore
          return new Date(b?.createdAt) - new Date(a?.createdAt);
        }
        //@ts-ignore
        return new Date(a?.createdAt) - new Date(b?.createdAt);
      });
    });
  }, [sort, transactions]);

  useEffect(() => {
    onGetTransaction();
  }, [id]);

  return (
    <div className={styles.wrapper}>
      {!isLoading && transactions?.length > 0 && (
        <div className={styles.header}>
          <div className={styles.nameBlock}>
            <p>Amount paid</p> <ArrowSmallDownIcon />
          </div>

          <div
            onClick={() => setSort((prev) => (prev === 1 ? -1 : 1))}
            className={clsx(styles.dateBlock, {
              [styles.dateBlockActive]: sort === 1,
            })}
          >
            <p>Transaction date</p> <ArrowSmallDownIcon />
          </div>
        </div>
      )}
      <div className={styles.listContainer}>
        {!isLoading &&
          transactions.map((item, index) => (
            <TransactionItem item={item} key={index} />
          ))}
        {!isLoading && transactions?.length === 0 && (
          <div className={styles.emptyTitle}>There are no transactions</div>
        )}
      </div>
      {isLoading && (
        <div className={styles.loaderWrapper}>
          <PulseLoader color={"#7D4AFB"} />
        </div>
      )}
    </div>
  );
};

export default TransactionsList;
