import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { ArrowDownIcon, ArrowSmallDownIcon } from "../../../assets";
import { Charts, TagStatsItem } from "../../../components";
import { IMainStats } from "../../../models";
import {
  getChartsStatistics,
  getMainStatistics,
} from "../../../services/statistics.service";
import styles from "./styles.module.scss";

const Statistics: FC = (): JSX.Element => {
  const [isLoadingMainStats, setIsLoadingMainStats] = useState<boolean>(false);

  const [mainStats, setMainStats] = useState<IMainStats>();

  const onGetMainStats = async () => {
    try {
      setIsLoadingMainStats(true);
      const { data } = await getMainStatistics();
      if (data?.success) {
        setMainStats(data);
      }
      setIsLoadingMainStats(false);
    } catch (error) {
      setIsLoadingMainStats(false);
    }
  };

  useEffect(() => {
    onGetMainStats();
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>Statistics</h4>
        </header>

        <section className={styles.contentContainer}>
          {isLoadingMainStats ? (
            <div className={styles.mainLoader}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          ) : (
            <>
              <div className={styles.chartsContainer}>
                <div className={styles.statisticsContainer}>
                  <div className={styles.allUsers}>
                    <p className={styles.largeRegular}>All Users</p>
                    <p className={styles.h2}>{mainStats?.users?.all}</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <h4>{mainStats?.users?.today}</h4>

                      {mainStats?.users?.percent !== null &&
                        mainStats?.users?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats && mainStats?.users.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats && mainStats?.users.percent < 0,
                              })}
                            >
                              {mainStats?.users?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className={styles.chart}>
                  <Charts />
                </div>
              </div>
              <div className={styles.infoContainer}>
                <div className={styles.infoBlock}>
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>Candidates</p>
                    <p className={styles.h5}>{mainStats?.candidates?.all}</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>

                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>
                        {mainStats?.candidates?.today}
                      </p>

                      {mainStats?.candidates?.percent !== null &&
                        mainStats?.candidates?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats &&
                                  mainStats?.candidates?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats &&
                                  mainStats?.candidates?.percent < 0,
                              })}
                            >
                              {mainStats?.candidates?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className={styles.infoBlock}>
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>Employers</p>
                    <p className={styles.h5}>{mainStats?.employers?.all}</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>
                        {mainStats?.employers?.today}
                      </p>

                      {mainStats?.employers?.percent !== null &&
                        mainStats?.employers?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats &&
                                  mainStats?.employers?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats &&
                                  mainStats?.employers?.percent < 0,
                              })}
                            >
                              {mainStats?.employers?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className={styles.infoBlock}>
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>Hires</p>
                    <p className={styles.h5}>{mainStats?.hired?.all}</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>
                        {mainStats?.hired?.today}
                      </p>

                      {mainStats?.hired?.percent !== null &&
                        mainStats?.hired?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats && mainStats?.hired?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats && mainStats?.hired?.percent < 0,
                              })}
                            >
                              {mainStats?.hired?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className={styles.infoBlock}>
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>Job Postings</p>
                    <p className={styles.h5}>{mainStats?.jobs?.all}</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>
                        {mainStats?.jobs?.today}
                      </p>

                      {mainStats?.jobs?.percent !== null &&
                        mainStats?.jobs?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats && mainStats?.jobs?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats && mainStats?.jobs?.percent < 0,
                              })}
                            >
                              {mainStats?.jobs?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className={styles.infoBlock}>
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>Applications</p>
                    <p className={styles.h5}>{mainStats?.applications?.all}</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>
                        {mainStats?.applications?.today}
                      </p>

                      {mainStats?.applications?.percent !== null &&
                        mainStats?.applications?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats &&
                                  mainStats?.applications?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats &&
                                  mainStats?.applications?.percent < 0,
                              })}
                            >
                              {mainStats?.applications?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className={styles.infoBlock}>
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>Videos</p>
                    <p className={styles.h5}>{mainStats?.videos?.all}</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>
                        {mainStats?.videos?.today}
                      </p>

                      {mainStats?.videos?.percent !== null &&
                        mainStats?.videos?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats && mainStats?.videos?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats && mainStats?.videos?.percent < 0,
                              })}
                            >
                              {mainStats?.videos?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>

                <div
                  style={{ padding: "25px 24px 25px 0px", borderTop: "none" }}
                  className={styles.infoBlock}
                >
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>Candidate Videos</p>
                    <p className={styles.h5}>
                      {mainStats?.candidateVideos?.all}
                    </p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>
                        {mainStats?.candidateVideos?.today}
                      </p>

                      {mainStats?.candidateVideos?.percent !== null &&
                        mainStats?.candidateVideos?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats &&
                                  mainStats?.candidateVideos?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats &&
                                  mainStats?.candidateVideos?.percent < 0,
                              })}
                            >
                              {mainStats?.candidateVideos?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    borderTop: "none",
                    borderRight: "1px solid rgba(227, 230, 236, 1)",
                  }}
                  className={styles.infoBlock}
                >
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>Influencer Videos</p>
                    <p className={styles.h5}>
                      {mainStats?.proposiotionVideos?.all}
                    </p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>
                        {mainStats?.proposiotionVideos?.today}
                      </p>

                      {mainStats?.proposiotionVideos?.percent !== null &&
                        mainStats?.proposiotionVideos?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats &&
                                  mainStats?.proposiotionVideos?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats &&
                                  mainStats?.proposiotionVideos?.percent < 0,
                              })}
                            >
                              {mainStats?.proposiotionVideos?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.tagsContainer}>
                <div className={styles.infoBlock}>
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>Tags</p>
                    <p className={styles.h5}>{mainStats?.tags?.all}</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>
                        {mainStats?.tags?.today}
                      </p>
                      {mainStats?.tags?.percent !== null &&
                        mainStats?.tags?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats && mainStats?.tags?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats && mainStats?.tags?.percent < 0,
                              })}
                            >
                              {mainStats?.tags?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className={styles.tagsStatistics}>
                  <p className={styles.title}>Most Popular Tags</p>
                  <div className={styles.tagsContainer}>
                    {mainStats?.tags?.rating?.map((item, index) => (
                      <TagStatsItem
                        title={item.value}
                        percent={item.percent}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
    </>
  );
};

export default Statistics;
