import { ChangeEvent, FC, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import {
  CalendarIcon,
  ClockIcon,
  CrossIcon,
  LocationPoint,
  SuitCaseIcon,
} from "../../assets";
import {
  Button,
  IconTag,
  Input,
  Radio,
  Selector,
  Textarea,
  UserPreview,
} from "../../components";
import { BlockReason } from "../../constants";
import { IJob } from "../../models";
import { deleteJob } from "../../services/job.service";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  item: IJob;
  onGetJobs: (page: number, limit: number, search: string) => void;
}

interface DeleteJobPostingProps {
  setIsOpen: (isOpen: boolean) => void;
  onGetJobs: (page: number, limit: number, search: string) => void;
  item: IJob;
}

const DeleteJobPosting: FC<DeleteJobPostingProps> = ({
  setIsOpen,
  onGetJobs,
  item,
}): JSX.Element => {
  const [blockReason, setBlockReason] = useState<string>("Other");
  const [otherReason, setOtherReason] = useState<string>("");
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

  const onChangeOtherReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
  };

  const onDelete = async () => {
    try {
      setIsLoadingDelete(true);
      const { data } = await deleteJob(
        item.id,
        blockReason === "Other" ? otherReason : blockReason
      );
      if (data?.success) {
        onGetJobs(1, 20, "");
        setIsOpen(false);
      }
      setIsLoadingDelete(false);
    } catch (error) {
      setIsLoadingDelete(false);
    }
  };

  return (
    <div className={styles.deleteJobPostingInner}>
      <h5 className={styles.title}>Delete Job Posting</h5>
      <CrossIcon onClick={() => setIsOpen(false)} className={styles.closeBtn} />
      <p className={styles.subTitle}>
        Choose a reason why you want to delete the job posting:
      </p>
      <div className={styles.reasonList}>
        {BlockReason.map((item, index) => {
          return (
            <div key={index} className={styles.radioOption}>
              <Radio
                isCheked={blockReason === item}
                setIsCheked={() => setBlockReason(item)}
              />
              <p className={styles.radioTitle}>{item}</p>
            </div>
          );
        })}
        {blockReason === "Other" && (
          <Textarea
            placeholder="Message"
            value={otherReason}
            onChange={onChangeOtherReason}
            maxLength={240}
          />
        )}
      </div>
      <Button
        loading={isLoadingDelete}
        disabled={blockReason === "Other" && !otherReason}
        title={"Delete"}
        size={"small"}
        styleType={"filled"}
        onClick={onDelete}
        backgroundColor={
          blockReason === "Other" && !otherReason ? "#B2B3BD" : "#FF3C3C"
        }
      />
    </div>
  );
};

const JobPosting: FC<Props> = ({
  isOpen,
  setIsOpen,
  item,
  onGetJobs,
}): JSX.Element => {
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      {isOpenDelete ? (
        <DeleteJobPosting
          setIsOpen={setIsOpen}
          onGetJobs={onGetJobs}
          item={item}
        />
      ) : (
        <div className={styles.innerContainer}>
          <h5 className={styles.title}>Job Posting</h5>
          <CrossIcon
            onClick={() => setIsOpen(false)}
            className={styles.closeBtn}
          />
          <header className={styles.header}>
            <div className={styles.infoBlock}>
              <h4 className={styles.name}>{item.title}</h4>
              <UserPreview
                onClick={() => navigate(`/companies/${item?.employer_id}`)}
                image={item.employer.image}
                title={item.employer.company}
                verified={item?.employer?.verificationStatus === "Approved"}
              />
            </div>

            <Button
              onClick={() => setIsOpenDelete(true)}
              title={"Delete"}
              size={"small"}
              styleType={"filled"}
              style={{ width: "93px", backgroundColor: "#FF3C3C" }}
            />
          </header>
          <div className={styles.tagsContainer}>
            <IconTag icon={<LocationPoint />} title={item.location} />
            <IconTag icon={<SuitCaseIcon />} title={item.workplace} />
            <IconTag icon={<ClockIcon />} title={item.employment} />
            <div className={styles.calendar}>
              <IconTag icon={<CalendarIcon />} title={"Nov 23, 2022"} />
            </div>
          </div>
          <section className={styles.contentContainer}>
            <div className={styles.descriptionBlock}>
              <p className={styles.sectionTitle}>Job description</p>
              <p className={styles.description}>{item.description}</p>
            </div>
            {item.video && (
              <div className={styles.videoBlock}>
                <p className={styles.sectionTitle}>Video</p>
                <video
                  src={item.video}
                  controls
                  className={styles.video}
                ></video>
              </div>
            )}
          </section>
        </div>
      )}
    </Modal>
  );
};

export default JobPosting;
