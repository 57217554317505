import { FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { ArrowSmallDownIcon } from "../../assets";
import clsx from "clsx";
import ReferralItem from "./components/ReferralItem";
import { getCandidateReferrals } from "../../services/referral.service";
import { PulseLoader } from "react-spinners";
import PaginationButtons from "../PaginationButtons";

interface IProps {
  candidateId: string;
}

interface IReferral {
  createdAt: string;
  firstName: string;
  id: string;
  lastName: string;
  location: string;
  image: string;
}
const limit = 20;
const ReferralsList: FC<IProps> = ({ candidateId }) => {
  const [sort, setSort] = useState<1 | -1>(1);

  const page = useRef<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [referrals, setReferrals] = useState<IReferral[]>([]);

  const onGetReferrals = async () => {
    try {
      setIsLoading(true);
      const { data } = await getCandidateReferrals(
        candidateId,
        page.current,
        limit,
        sort
      );
      if (data?.success) {
        setPagesCount(Math.ceil(data?.count / limit));
        setReferrals(data?.candidates);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetReferrals();
  }, [sort]);

  return (
    <div className={styles.wrapper}>
      {!isLoading && referrals?.length > 0 && (
        <div className={styles.header}>
          <div className={styles.nameBlock}>
            <p>Candidate</p> <ArrowSmallDownIcon />
          </div>
          <div className={styles.nameBlock}>
            <p>Location</p> <ArrowSmallDownIcon />
          </div>
          <div
            onClick={() => setSort((prev) => (prev === 1 ? -1 : 1))}
            className={clsx(styles.dateBlock, {
              [styles.dateBlockActive]: sort === 1,
            })}
          >
            <p>Registration date</p> <ArrowSmallDownIcon />
          </div>
        </div>
      )}
      <div className={styles.listContainer}>
        {isLoading && (
          <div className={styles.loaderWrapper}>
            <PulseLoader color={"#7D4AFB"} />
          </div>
        )}
        {!isLoading &&
          referrals.map((item, index) => (
            <ReferralItem item={item} key={index} />
          ))}
        {!isLoading && referrals?.length === 0 && (
          <div className={styles.emptyTitle}>There are no referrals</div>
        )}
      </div>
      <PaginationButtons
        pagesCount={pagesCount}
        page={page.current}
        onPress={(index) => {
          page.current = index;
          onGetReferrals();
        }}
      />
    </div>
  );
};

export default ReferralsList;
