import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

export const getMainStatistics = (): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get("statistic/main")
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getChartsStatistics = (
  type: "week" | "month" | "year"
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`statistic/${type}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
