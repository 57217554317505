export const BASE_URL = "https://dashboard.karizma.ai";
export const BASE_URL_FOR_SECURE_IMAGE =
  "https://dashboard.karizma.ai/employer/docs/";
export const StaffRole = ["Moderator", "Admin"];
export const BlockReason = [
  "Minor safety",
  "Adult nudity and sexual activities",
  "Bulling and harassment",
  "Illegal activities and regulated goods",
  "Other",
];

export const VideoScores = ["Low", "Moderate", "Average", "Elevated", "High"];
export const BusinessVerificationRejectReason = [
  "Documents are not valid/outdated",
  "Wrong type of documents",
  "Other",
];
export const reportTypes = ["User complaint", "Problem report"];
export const reportCategories = [
  "User's behavior",
  "Content on profile",
  "Found issue",
];
