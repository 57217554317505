import { FC } from "react";
import {
  SignIn,
  StaffManagement,
  Statistics,
  Reports,
  NewRequests,
  Applications,
  Candidates,
  Companies,
  JobPosting,
  CandidateProfile,
  CompanyProfile,
  BusinessVerification,
  Referrals,
  ReferralProfile,
  Influencers,
  Messages,
  Chat,
} from "../pages";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { getUser } from "../utils/tokenStorage";

const Navigation: FC = () => {
  const me = getUser();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="signIn" element={<SignIn />} />
        <Route path="/*" element={<PrivateRoute />}>
          <Route index path="statistics" element={<Statistics />} />
          <Route path="new-requests" element={<NewRequests />} />
          <Route
            path="business-verification"
            element={<BusinessVerification />}
          />
          <Route path="referrals" element={<Referrals />} />
          <Route path="referrals/:id" element={<ReferralProfile />} />
          <Route path="influencers" element={<Influencers />} />
          <Route path="messages" element={<Messages />} />
          <Route
            path="messages/candidate/:id"
            element={<Chat type="candidate" />}
          />
          <Route
            path="messages/employer/:id"
            element={<Chat type="employer" />}
          />

          <Route path="reports" element={<Reports />} />
          <Route path="applications" element={<Applications />} />
          <Route path="candidates" element={<Candidates />} />
          <Route path="candidates/:id" element={<CandidateProfile />} />
          <Route path="companies" element={<Companies />} />
          <Route path="companies/:id" element={<CompanyProfile />} />
          <Route path="job-posting" element={<JobPosting />} />
          <Route path="staff-management" element={<StaffManagement />} />
          <Route path="*" element={<Navigate to="/statistics" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
