import { FC } from "react";
import { CheckBox } from "..";
import styles from "./styles.module.scss";

interface Props {
  isChecked: boolean;
  onSelect: () => void;
  title: string;
}

const CheckItem: FC<Props> = ({ isChecked, onSelect, title }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <CheckBox isChecked={isChecked} setIsChecked={onSelect} />
      <p>{title}</p>
    </div>
  );
};

export default CheckItem;
