import { format } from "date-fns";
import { FC } from "react";
import { IEducation } from "../../models";
import styles from "./styles.module.scss";

interface Props {
  item: IEducation;
}

const EducationItem: FC<Props> = ({ item }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.name}>{item.school}</p>
      <p className={styles.subtitle}>{item.degree}</p>
      <p className={styles.date}>
        {format(new Date(item?.startDate), "yyyy")} -{" "}
        {format(new Date(item?.endDate), "yyyy")}
      </p>
    </div>
  );
};

export default EducationItem;
