import { FC } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import AvatarPlaceholder from "../../assets/images/avatar.png";
import AvatarCompanyPlaceholder from "../../assets/images/companyAvatar.png";
import { IMessage } from "../../models/IMessage";
import { format } from "date-fns";
import {
  CheckBigIcon,
  CheckCircleIcon,
  ClockIcon,
  CrossCircleIcon,
  CrossIcon,
  LocationPoint,
  MoneyBillIcon,
  ViewedIcon,
} from "../../assets";
import Button from "../Button";

interface IMessageItemProps {
  direction: "right" | "left";
  item: IMessage;
  userImage: string | null | undefined;
  interlocutorImage: string | null;
  type: "candidate" | "employer";
  userName: string;
  interlocutorName: string | undefined;
  isNextHaveSameAuthor: boolean;
}

const MessageItem: FC<IMessageItemProps> = ({
  direction,
  item,
  userImage,
  interlocutorImage,
  type,
  interlocutorName,
  userName,
  isNextHaveSameAuthor,
}) => {
  if (
    typeof item?.content !== "string" &&
    item?.type === "Info" &&
    //@ts-ignore
    item?.content?.type === "Start"
  ) {
    if (
      //@ts-ignore
      item?.content?.initiator !==
      (type === "employer" ? "candidate" : "employer")
    ) {
      return (
        <p
          className={styles.infoMessage}
        >{`${userName} started chat with ${interlocutorName}`}</p>
      );
    } else {
      return (
        <p
          className={styles.infoMessage}
        >{`${interlocutorName} started chat with ${userName}`}</p>
      );
    }
  }

  if (item?.type === "Image" && typeof item?.content === "string") {
    return (
      <div
        className={clsx(styles.wrapper, {
          [styles.leftWrapper]: direction === "left",
          [styles.rightWrapper]: direction === "right",
          [styles.sameAuthorWrapper]: isNextHaveSameAuthor,
          [styles.differentAuthorWrapper]: !isNextHaveSameAuthor,
        })}
      >
        <img src={item?.content} className={styles.image} />
      </div>
    );
  }

  if (item?.type === "Offer" && typeof item?.content !== "string") {
    return (
      <div
        style={{
          //@ts-ignore
          borderColor: item?.content?.accepted
            ? "#2ADC1B"
            : //@ts-ignore

            !item?.content?.accepted && !item?.content?.declinedReason
            ? "#7D4AFB"
            : "#FF3C3C",
        }}
        className={styles.offerWrapper}
      >
        <p className={styles.offerTitle}>
          {type === "employer" ? userName : interlocutorName} Sent an Offer
        </p>
        <div className={styles.iconTag}>
          <LocationPoint />
          <span className={styles.iconTitle}>
            {item?.content?.location?.city},{" "}
            {item?.content?.location?.countryShort}
          </span>
        </div>
        <div className={styles.iconTagRowContainer}>
          <div className={styles.iconTag}>
            <ClockIcon />
            <span className={styles.iconTitle}>
              {item?.content?.employment}
            </span>
          </div>
          <div className={styles.iconTag}>
            <MoneyBillIcon />
            <span className={styles.iconTitle}>
              {
                //@ts-ignore
                item?.content?.amount
              }{" "}
              {
                //@ts-ignore

                item?.content?.currency
              }
              {"/"}
              {
                //@ts-ignore
                item?.content?.compensationType?.toLowerCase()
              }
            </span>
          </div>
        </div>
        <p className={styles.offerDescription}>
          {
            //@ts-ignore
            item?.content?.description
          }
        </p>
        {
          //@ts-ignore
          item?.content?.accepted && (
            <div className={styles.offerFooter}>
              <Button
                width={"100%"}
                leftIcon={<CheckCircleIcon style={{ fill: "#2ADC1B" }} />}
                backgroundColor="rgba(132, 214, 174, 0.2)"
                color="#2ADC1B"
                isShowBorder={false}
                title="Offer Accepted"
                size="x-small"
                styleType="filled"
              />
            </div>
          )
        }
        {
          //@ts-ignore
          !item?.content?.accepted && item?.content?.declinedReason && (
            <div className={styles.offerFooter}>
              <Button
                width={"100%"}
                leftIcon={
                  <CrossCircleIcon style={{ fill: "rgba(255, 60, 60, 1)" }} />
                }
                backgroundColor="rgba(255, 60, 60, 0.2)"
                color="rgba(255, 60, 60, 1)"
                isShowBorder={false}
                //@ts-ignore
                title={item?.content?.declinedReason}
                size="x-small"
                styleType="filled"
              />
            </div>
          )
        }
      </div>
    );
  }
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.leftWrapper]: direction === "left",
        [styles.rightWrapper]: direction === "right",
        [styles.sameAuthorWrapper]: isNextHaveSameAuthor,
        [styles.differentAuthorWrapper]: !isNextHaveSameAuthor,
      })}
    >
      {direction === "left" && (
        <img
          src={
            interlocutorImage ||
            (type === "candidate"
              ? AvatarCompanyPlaceholder
              : AvatarPlaceholder)
          }
          className={styles.avatar}
        />
      )}
      <div
        className={clsx(styles.messageWrapper, {
          [styles.leftMessageWrapper]: direction === "left",
          [styles.rightMessageWrapper]: direction === "right",
        })}
      >
        <p className={styles.message}>
          {typeof item?.content === "string" ? item?.content : ""}
        </p>
        <span className={styles.time}>
          {format(new Date(item?.createdAt), "HH:mm")}
          {direction === "right" && item?.viewed && <ViewedIcon />}
        </span>
      </div>
      {direction === "right" && (
        <img
          src={
            userImage ||
            (type === "candidate"
              ? AvatarPlaceholder
              : AvatarCompanyPlaceholder)
          }
          className={styles.avatar}
        />
      )}
    </div>
  );
};

export default MessageItem;
