import { FC, useState } from "react";
import styles from "./styles.module.scss";
import { Video } from "../../modals";
import { ICandidate, IEmployer, IVideo } from "../../models";

interface Props {
  item: IVideo;
  user: IEmployer | ICandidate | undefined;
}

const VideoItem: FC<Props> = ({ item, user }): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  return (
    <>
      <Video
        item={item}
        user={user}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
      />
      <div onClick={() => setIsOpenModal(true)} className={styles.wrapper}>
        <img
          src={item?.thumbnail}
          alt={"thumbnail"}
          className={styles.thumbnail}
        />
        <div className={styles.shadow} />
      </div>
    </>
  );
};

export default VideoItem;
