import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowLineIcon,
  ArrowSmallDownIcon,
  InstagramIcon,
  LinkedInIcon,
  VerifiedIcon,
} from "../../../assets";
import {
  Button,
  Chat,
  ChatItem,
  CvItem,
  EducationItem,
  EmploymentItem,
  SearchInput,
} from "../../../components";
import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../../assets/images/avatar.png";
import PlaceholderCompanyAvatar from "../../../assets/images/companyAvatar.png";
import VideoItem from "../../../components/VideoItem";
import { ICandidate, IEmployer } from "../../../models";
import {
  deleteCandidate,
  getCandidateById,
  getCandidateChats,
} from "../../../services/candidate.service";
import { PulseLoader } from "react-spinners";
import format from "date-fns/format";
import { openLink } from "../../../utils";
import { BlockUser, Delete } from "../../../modals";
import {
  getEmployerById,
  getEmployerChats,
} from "../../../services/employer.service";
import { useDebounce } from "../../../hooks";

interface Props {
  type: "candidate" | "employer";
}

const CandidateProfile: FC<Props> = ({ type }): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<"About" | "Videos">("About");
  const [candidate, setCandidate] = useState<ICandidate>();
  const [employer, setEmployer] = useState<IEmployer>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowBlockModal, setIsShowBlockModal] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 300);
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const [chats, setChats] = useState<any[]>([]);
  const [filteredChats, setFilteredChats] = useState<any[]>([]);
  const [activeChatId, setActiveChatId] = useState("");

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const params = useParams();
  const navigate = useNavigate();
  const onGetCandidateChats = async (id: string) => {
    try {
      setIsLoadingChats(true);

      const { data } = await getCandidateChats(id);
      if (data?.success) {
        setChats(data?.chats);
      }
    } catch (error) {
    } finally {
      setIsLoadingChats(false);
    }
  };
  const onGetEmployerChats = async (id: string) => {
    try {
      setIsLoadingChats(true);

      const { data } = await getEmployerChats(id);
      if (data?.success) {
        setChats(data?.chats);
      }
    } catch (error) {
    } finally {
      setIsLoadingChats(false);
    }
  };

  const onGetCandidate = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getCandidateById(id);
      if (data?.success) {
        setCandidate(data?.candidate);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onGetEmployer = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getEmployerById(id);
      if (data?.success) {
        setEmployer(data?.employer);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params.id && type === "candidate") {
      onGetCandidate(params.id);
      onGetCandidateChats(params.id);
    } else if (params.id && type === "employer") {
      onGetEmployer(params.id);
      onGetEmployerChats(params.id);
    }
  }, [params]);

  useEffect(() => {
    const copy = [...chats];
    const filteredArray = copy.filter(
      (item) =>
        item?.firstName
          ?.toLowerCase()
          ?.includes(debounceSearch?.toLowerCase()) ||
        item?.lastName
          ?.toLowerCase()
          ?.includes(debounceSearch?.toLowerCase()) ||
        item?.company?.toLowerCase()?.includes(debounceSearch?.toLowerCase())
    );
    filteredArray.sort(
      //@ts-ignore

      (a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt)
    );
    setFilteredChats(filteredArray);
  }, [debounceSearch, chats]);
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div onClick={() => navigate(-1)} className={styles.backBtn}>
            <ArrowLineIcon />
            <span>
              {type === "employer" ? "Company" : "Candidate"} Messages
            </span>
          </div>
        </div>
        <div className={styles.contentContainer}>
          {isLoading ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          ) : (
            <>
              <div className={styles.header}>
                <div className={styles.info}>
                  <img
                    src={
                      candidate?.image ||
                      employer?.image ||
                      (type === "candidate"
                        ? PlaceholderAvatar
                        : PlaceholderCompanyAvatar)
                    }
                    alt="avatar"
                    className={styles.avatar}
                  />
                  <div className={styles.nameWrapper}>
                    <p className={styles.name}>
                      {type === "candidate"
                        ? `${candidate?.firstName} ${candidate?.lastName}`
                        : employer?.company}
                      {employer?.verificationStatus === "Approved" && (
                        <VerifiedIcon
                          style={{ marginLeft: 10, width: 18, height: 18 }}
                        />
                      )}
                    </p>
                    <p className={styles.location}>
                      {candidate?.location || employer?.location}
                    </p>
                    <p className={styles.location}>
                      {candidate?.email || employer?.email}
                    </p>
                  </div>
                </div>
                {type === "employer" && (
                  <div className={styles.followersStatsWrapper}>
                    <div className={styles.block}>
                      <p className={styles.counter}>
                        {employer?.favoriteCount}
                      </p>
                      <p className={styles.subtitle}>Followers</p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.block}>
                      <p className={styles.counter}>{employer?.jobsCount}</p>
                      <p className={styles.subtitle}>Job Postings</p>
                    </div>
                  </div>
                )}
                <div className={styles.socialbtnWrapper}>
                  {(employer?.linkedin || candidate?.linkedin) && (
                    <LinkedInIcon
                      onClick={() =>
                        openLink(
                          employer?.linkedin || candidate?.linkedin || ""
                        )
                      }
                      className={styles.socialBtn}
                    />
                  )}
                  {(employer?.instagram || candidate?.instagram) && (
                    <InstagramIcon
                      onClick={() => {
                        openLink(
                          `https://www.instagram.com/${(
                            employer?.instagram || candidate?.instagram
                          )?.slice(1)}`
                        );
                      }}
                      className={styles.socialBtn}
                    />
                  )}
                </div>
              </div>
              <div className={styles.rowContainer}>
                <div className={styles.chatsWrapper}>
                  <SearchInput
                    placeholder="Search"
                    value={search}
                    onChange={onChangeSearch}
                    maxWidth={"100%"}
                  />
                  <div className={styles.chatsContainer}>
                    {filteredChats.map((item, index) => (
                      <ChatItem
                        userName={
                          employer?.company || `${candidate?.firstName}`
                        }
                        activeChatId={activeChatId}
                        setActiveChatId={setActiveChatId}
                        type={type}
                        item={item}
                        key={item?.id}
                      />
                    ))}
                    {filteredChats?.length === 0 && (
                      <p className={styles.emptyTitle}>No chats</p>
                    )}
                  </div>
                </div>
                {activeChatId && (
                  <Chat
                    interlocutorImage={
                      chats?.find((item) => item?.id === activeChatId)?.image
                    }
                    interlocutorName={
                      chats?.find((item) => item?.id === activeChatId)
                        ?.firstName ||
                      chats?.find((item) => item?.id === activeChatId)?.company
                    }
                    offer={
                      chats?.find((item) => item?.id === activeChatId)?.offer
                    }
                    userImage={candidate?.image || employer?.image}
                    userName={
                      employer?.company ||
                      `${candidate?.firstName} ${candidate?.lastName}`
                    }
                    id={activeChatId}
                    userId={params?.id}
                    type={type}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CandidateProfile;
