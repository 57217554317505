import { Dispatch, FC, SetStateAction } from "react";
import styles from "./styles.module.scss";
import AvatarPlaceholder from "../../assets/images/avatar.png";
import AvatarPlaceholderCompany from "../../assets/images/companyAvatar.png";
import {
  differenceInCalendarDays,
  differenceInDays,
  format,
  isToday,
} from "date-fns";
import clsx from "clsx";
import { VerifiedIcon } from "../../assets";

interface IProps {
  item: any;
  setActiveChatId: Dispatch<SetStateAction<string>>;
  activeChatId: string;
  type: "candidate" | "employer";
  userName: string;
}

const ChatItem: FC<IProps> = ({
  item,
  type,
  activeChatId,
  userName,
  setActiveChatId,
}): JSX.Element => {
  const renderLastMessage = (chat: any) => {
    if (chat?.offer) {
      return "Offer";
    }
    if (chat?.last?.type === "Info" && chat?.last?.content?.type === "Start") {
      if (
        chat?.last?.content?.initiator !==
        (type === "employer" ? "candidate" : "employer")
      ) {
        return `${userName} started chat with ${
          chat?.firstName || chat?.company
        }`;
      } else {
        return `${
          chat?.firstName || chat?.company
        } started chat with ${userName}`;
      }
    } else if (chat?.last?.type === "Text") {
      return chat?.last?.content;
    } else if (chat?.last?.type === "Image") {
      return "Image";
    } else if (
      chat?.last?.type === "Offer" ||
      chat?.offer ||
      chat?.last === null
    ) {
      return "Offer";
    } else if (chat?.last?.type === "Interview") {
      return "Interview";
    }
  };
  return (
    <div
      onClick={() => setActiveChatId(item?.id)}
      className={clsx(styles.wrapper, {
        [styles.activeWrapper]: activeChatId === item?.id,
      })}
    >
      <img
        src={
          item?.image ||
          (type === "employer" ? AvatarPlaceholder : AvatarPlaceholderCompany)
        }
        className={styles.avatar}
      />
      <div className={styles.contentContainer}>
        <span className={styles.name}>
          {type === "employer"
            ? `${item?.firstName} ${item?.lastName}`
            : item?.company}
        </span>
        <span className={styles.message}>{renderLastMessage(item)}</span>
      </div>
      <span className={styles.date}>
        {isToday(new Date(item?.updatedAt))
          ? format(new Date(item?.updatedAt), "HH:mm")
          : differenceInCalendarDays(new Date(item?.updatedAt), new Date()) <= 7
          ? `${Math.abs(
              differenceInCalendarDays(new Date(item?.updatedAt), new Date())
            )}d ago`
          : format(new Date(item?.updatedAt), "E")}
      </span>
    </div>
  );
};

export default ChatItem;
